import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  Collapse,
  FormGroup,
  Form,
  Input,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

import { useWindowHeight, useWindowWidth } from '@wojtekmaj/react-hooks';
import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';


function Headers() {
    
  const screenWidth = useWindowWidth();
  
  // navbar collapses states and functions
  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  const [navbarOpen2, setNavbarOpen2] = React.useState(false);
  const [navbarOpen3, setNavbarOpen3] = React.useState(false);
  // header 3 carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [ofertasItems, setOfertasItems] = React.useState([]);
    
  React.useEffect(() => {
      
    loadOfertas();
    
    return () => {
        
    }

  }, []);
  
  const loadOfertas = () => {
      
      fetch(`https://www.promo.com.mx/ofertas/config.json?t=${new Date().getTime()}`, {
	method: "GET" ,
	timeoutInterval: 15000,
        headers: {
            'Accept': 'application/json; charset=utf-8',
        }
      })  
      .then(response => { 
          console.log('response', response);
          return response.json();
      })
      .then(responseJson => {
          
        console.log('config.json responseJson', responseJson);
        updateOfertasItems(responseJson.total, responseJson.lastUpdate);
        
      })
      .catch(error => {
        console.error(error);
      });
  }
  
  
  const updateOfertasItems = (total, lastUpdate) => {
      
      let nOfertasItems = [];
      
      const ismob = isMobile ? 'mob_' : '';
      
      for (let i = 1; i <= total; i++) {
        
        let item = {
            src: `url(\"https://www.promo.com.mx/ofertas/oferta_${ismob}${i}.jpg?t=${lastUpdate}\")`,
            content: (
            <Row>
        
                <Col className="ml-auto mr-auto slider-oferta" md="6">
          
                </Col>
            </Row>
            ),
            altText: "",
            caption: ""
        }
        
        nOfertasItems.push(item);
      }
      
      setOfertasItems(nOfertasItems);
      
  }
  
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === ofertasItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? ofertasItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  return (
    <>
      {navbarOpen1 || navbarOpen2 || navbarOpen3 ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setNavbarOpen1(false);
            setNavbarOpen2(false);
            setNavbarOpen3(false);
          }}
        />
      ) : null}
        
        <div className="slide-header">
          
          <Carousel pause={true} activeIndex={activeIndex} next={next} previous={previous}>
            <CarouselIndicators
              items={ofertasItems}
              activeIndex={activeIndex}
              onClickHandler={goToIndex}
            />
            {ofertasItems.map(item => {
              return (
                <CarouselItem
                  onExiting={onExiting}
                  onExited={onExited}
                  key={item.src}
                >
                  <div className="page-header header-filter">
                    <div
                      className="page-header-image slider-oferta"
                      style={{
                        backgroundImage: item.src
                      }}
                    ></div>
                    <div className="content">
                      <Container className="text-left">
                        {item.content}
                      </Container>
                    </div>
                  </div>
                </CarouselItem>
              );
            })}
            <a
              className="left carousel-control carousel-control-prev"
              data-slide="prev"
              href="#promo"
              onClick={e => {
                e.preventDefault();
                previous();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-left" />
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="right carousel-control carousel-control-next"
              data-slide="next"
              href="#promo"
              onClick={e => {
                e.preventDefault();
                next();
              }}
              role="button"
            >
              <span className="now-ui-icons arrows-1_minimal-right" />
              <span className="sr-only">Next</span>
            </a>
          </Carousel>
      </div>
    </>
  );
}

export default Headers;
