import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import {dGlobal} from "functions/DGlobal";


// core components
import Post3Header from "components/Headers/Post3Header.js";

import ceoIcon from 'assets/img/ceo.svg';
import managerIcon from 'assets/img/manager.svg';
import seller1Icon from 'assets/img/seller1.svg';
import seller2Icon from 'assets/img/seller2.svg';

import r3_icon from 'assets/img/r3_icon.svg';
import ambient_icon from 'assets/img/ambient_icon.svg';
import bio_icon from 'assets/img/bio_icon.svg';

function Post3() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper">
        <Post3Header />
        
        <div className="text-center">
          <Container>
            <Row>
            <Col md="5">
            <div className="aboutus-imgheader" style={{
            backgroundImage: "url(" + require("assets/img/post3_image.jpg") + ")", backgroundSize: "cover", backgroundPosition: "center center"
          }}>
                
              
            </div>    
            </Col>
            <Col className="mr-auto ml-auto" md="6">
              <h4 className="description text-justify">
                

                Las empresas necesitan productos promocionales como un medio rentable para llegar a más clientes potenciales. Este es un método de mercadotécnia de bajo 
                costo para atraer clientes. Incluso las empresas globales establecidas recurren a esta estrategia de mercadotécnia. Para las pequeñas empresas, o las que 
                recién comienzan, los productos promocionales ayudan a reducir su presupuesto de mercadotécnia y siguen llamando la atención de las personas. Los productos 
                promocionales se han utilizado como tácticas de mercadotécnia rentables durante años. Desde nuevos negocios hasta grandes empresas, estos obsequios son los 
                favoritos de los vendedores.<br /><br /> 
                
                
                

              </h4>
            </Col>  
            </Row>  
            
            <Row>
            <Col className="mr-auto ml-auto" md="11">
              <h4 className="description text-justify">
                Un estudio de sage world ha enumerado algunas estadísticas interesantes sobre el uso de productos promocionales. Éstos son algunas de ellas:<br /><br />

                - Seis de cada 10 consumidores tienen productos promocionales con ellos por hasta dos años.<br />
                - El 85% de las personas hacen negocios con el anunciante después de recibir un artículo promocional.<br />
                - El 31% de los consumidores posee una bolsa promocional.<br />  
                - El 63% de los consumidores regalan sus productos promocionales cuando no son necesarios.<br />

              </h4>
            </Col>  
            
            </Row>  
            <div className="separator-line bg-info"></div>
         
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Más Estadísticas</h2>
                    <h4 className="description text-justify">
                      La importancia de los productos promocionales para la promoción empresarial se puede medir a partir de estas estadísticas. Según un estudio realizado por 
                      PPAI, el 94% de las personas recuerdan dónde recibieron un regalo promocional. El estudio revela que al 83% de los consumidores les gusta obtener productos 
                      promocionales que tengan un mensaje publicitario. Quienes reciben los productos, el 85% de ellos hacen negocios con el anunciante. Además, el 58% de los 
                      consumidores encuestados dijeron que conservan los artículos de regalo de un año a cuatro años. Otra estadística importante revelada por el estudio es que 
                      el 89% de los consumidores dijeron que podían recordar el nombre del anunciante o el nombre de la empresa durante 24 meses después de recibir los artículos. 
                      Además, los productos promocionales obtienen 500% más de referencias de clientes que están satisfechos con los artículos de regalo. Estas estadísticas 
                      cruciales ponen énfasis en la importancia de los productos promocionales para la mercadotécnia y el reconocimiento de marca.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Entre empresas</h2>
                    <h4 className="description text-justify">
                      Una regla general que la mayoría de los vendedores siguen para la campaña de productos promocionales es regalar los artículos de uso diario. Estos regalos 
                      pueden ser bolígrafos, calendarios, Mousepad, paraguas, botellas de agua, bálsamos labiales, etc. Sin embargo, los expertos en mercadotécnia elaboran una 
                      estrategia creativa. Eligen los artículos que no solo son útiles sino únicos y de alta calidad para un impacto más amplio.<br /><br /> 

                      ¡Te animamos a que conozcas nuestra amplia variedad de artículos promocionales! Manejamos todo tipo de promocionales, desde Escritura, oficina, deporte, 
                      belleza, corporativos y muchos más!<br /><br /> 

                      
                    </h4>
                    <h4><b>Si requieres de más información o alguna cotización ¡No olvides contactarnos!</b>
                    <br /><br /><br /><br /><br /><br />
                    </h4>
                  </Col>
                </Row>
               
              </Container>
            </div>
        
      </div>
    </>
  );
}

export default Post3;
