import React from "react";


import BasePageView from "views/BasePageView.js";

import Datos from "components/Datos.js";


function DatosView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Datos />
      </div>
      </BasePageView>
  );
}

export default DatosView;
