import React from "react";

import BasePageView from "views/BasePageView.js";
import Header from "components/Header.js";
import EditOfertasSlider from "components/EditOfertasSlider.js";

function EditOfertasView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <EditOfertasSlider />
        
        
      </div>
      </BasePageView>
  );
}

export default EditOfertasView;
