import React, { useState, useCallback, useRef } from "react";

// reactstrap components
import { Card, Container, Row, Col, Button } from "reactstrap";

import FileViewer from "react-file-viewer";

function Privacidad() {
    
  const onError = e => {
    console.log(e, "error in file-viewer");
  };  
  
  return (
    <>
      <div className="cd-section" id="features">

        <div id="bancarios"></div>
        <div className="">
          <Container>
          
            <div style={{ height: '100%', width: '100%' }}>
                <FileViewer fileType="pdf" filePath="/privacidad.pdf" onError={onError} />
            </div>
            
            
            
          </Container>
        </div>
        
        
      </div>
      
    </>
  );
}

export default Privacidad;
