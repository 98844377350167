import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import {dGlobal} from "functions/DGlobal";


// core components
import AboutUsHeader from "components/Headers/AboutUsHeader.js";

import ceoIcon from 'assets/img/ceo.svg';
import managerIcon from 'assets/img/manager.svg';
import seller1Icon from 'assets/img/seller1.svg';
import seller2Icon from 'assets/img/seller2.svg';

import r3_icon from 'assets/img/r3_icon.svg';
import ambient_icon from 'assets/img/ambient_icon.svg';
import bio_icon from 'assets/img/bio_icon.svg';

function AboutUs() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper">
        <AboutUsHeader />
        <div>
          <Container>
            <Row>
            <Col md="5">
            <div className="aboutus-imgheader" style={{
            backgroundImage: "url(" + require("assets/img/about_image.jpg") + ")", backgroundSize: "cover", backgroundPosition: "center center"
          }}>
                
              
            </div>    
            </Col>
            <Col md="6">
              <h4 className="description text-center">
                PROMO Artículos Promocionales inició operaciones en abril de 2001, bajo el nombre de “REINBOU” dando idea de impresión a "full color".
Años más tardes con los cambios mundiales y transformaciones, se renovó a llegar a nuestro logotipo y nombre actual.
Realizando impresión en full color, serigrafía, bordado y en años recientes, grabado con láser.
Mas de 20 años de experiencia, hace que nuestros clientes nos avalen.

              </h4>
            </Col>  
            </Row>  
          </Container>  
        </div>  
        <div >
          <div className="text-center">
            <div >
              <Container>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h2 className="title">Misión</h2>
                    <h4 className="description">
                      Somos una empresa dedicada a los promocionales, confiable y enfocada en proporcionar productos de calidad y un servicio eficaz.<br/>
                      Seguir manteniendo nuestro nivel de satisfacción al cliente arriba del 98%, del total de las entregas, en tiempo y con calidad.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h2 className="title">Visión</h2>
                    <h4 className="description">
                      Ser una de las empresas líderes en promocionales en el estado de Jalisco, logrando satisfacer las necesidades del cliente.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h2 className="title">Valores</h2>
                    <h4 className="description">
                        Respeto, entre compañeros y clientes.<br />
                        Honestidad, hacia los colaboradores.<br />
                        Credibilidad, en los clientes.<br />
                        Confianza, entre colaboradores y clientes a nivel organización.<br />
                        Eficiencia, en el proceso de producción y atención al cliente.<br /><br />
                    </h4>    
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="8">
                    <h4 className="title">Estamos Comprometidos con el Medio Ambiente</h4>
                    <h4 className="description">
                        La presión sobre los recursos limitados del planeta y el reto del cambio climático requieren de una acción rápida y coordinada entre todos. En Promo aplicamos medidas multidisciplinarias para proteger la biodiversidad y reducir nuestro impacto en el consumo de agua, en el cambio climático y en el uso de recursos productivos y energéticos.
                    </h4>    
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-success icon-circle">
                        <img src={r3_icon} height="87px" />
                      </div>
                      <h4 className="info-title">Reducir, reciclar y reutilizar</h4>
                      <p className="description">
                        La regla de las 3 erres del reciclaje incluye tres conceptos: reducir, reciclar y reutilizar.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-info icon-circle">
                        <img src={ambient_icon} height="87px" />
                      </div>
                      <h4 className="info-title">Ser una empresa sostenible</h4>
                      <p className="description">
                        Una empresa sostenible es aquella que tiene en cuenta los aspectos medioambientales y sociales, además de los económicos. .
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-primary icon-circle">
                        <img src={bio_icon} height="87px" />
                      </div>
                      <h4 className="info-title">Promover lo biodegradable</h4>
                      <p className="description">
                        Cuando se dice que un producto o material es biodegradable significa que tiene la capacidad de descomponerse de forma natural y ecológica en un plazo relativamente corto.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <div className="separator-line bg-info"></div>
          <div className="projects-5">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Nuestras Ventajas y Fortalezas</h2>
                  <h4 className="description">
                    Somos apasionados, dedicados y, sobre todo, comprometidos en ofrecer soluciones integrales de promoción en mercadotecnia visual mediante artículos promocionales, para que juntos logremos los resultados que su empresa espera.
                  </h4>
                  <div className="section-space"></div>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto" md="5">
                  <Card
                    className="card-background card-background-product card-raised"
                    style={{
                      backgroundImage:
                        "url(" + require("assets/img/project8.jpg") + ")"
                    }}
                  >
                    <CardBody>
                      <CardTitle tag="h2">Fortalezas + Oportunidades</CardTitle>
                      <p className="card-description">
                        Conocer ampliamente nuestras ventajas competitivas, fortalezas y oportunidades permite dirigir positivamente nuestros esfuerzos para lograr los resultados trazados. 
                      </p>
                      <Badge className="badge-neutral">Somos auto-analíticos</Badge>
                    </CardBody>
                  </Card>
                </Col>
                <Col className="mr-auto" md="5">
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons ui-2_chat-round"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        +20 Años de Experiencia
                      </h4>
                      <p className="description">
                        La experiencia comercial se ha convertido en el diferenciador competitivo más importante.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons business_chart-pie-36"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Personal y equipo calificado</h4>
                      <p className="description">
                        La capacitación continua permite al personal de una empresa planear, mejorar, y realizar de manera más eficiente sus tareas.
                      </p>
                    </div>
                  </div>
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="now-ui-icons design-2_ruler-pencil"></i>
                    </div>
                    <div className="description">
                      <h4 className="info-title">Alta Precisión y Calidad</h4>
                      <p className="description">
                        La calidad dentro de una empresa es un factor importante que produce satisfacción a sus clientes.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
              <hr></hr>
              
            </Container>
          </div>
          <div className="team">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">Nuestros Líderes</h2>
                  <h4 className="description">
                    La principal característica con la que cuentan todos los grandes líderes que revolucionan el mundo de los negocios es la mentalidad emprendedora y la innovación en proyectos. 
                    <br />
                    Pero sobre todo lograr la satisfacción del cliente a través de los productos o servicios que se le ofrece.
                  </h4>
                </Col>
              </Row>
              <Row>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                            <img className="img rounded" src={ceoIcon} height="200px" />
                          
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Luis A. Venegas</CardTitle>
                          <h6 className="category">Director General</h6>
                          <p className="card-description">
                            Ingeniero en Computación.
                            <br/>
                            <a className="mail-to" href="mailto:luis@promo.com.mx">
                            <i className="now-ui-icons ui-1_email-85" style={{paddingRight: '6px'}}></i>
                            luis@promo.com.mx 
                            </a>
                          </p>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                          <img className="img rounded" src={managerIcon} height="200px" />
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Y. Nayeli Venegas</CardTitle>
                          <h6 className="category">Gerente</h6>
                          <p className="card-description">
                            Licenciada en Ciencias de la Comunicación.
                            <br/>
                            <a className="mail-to" href="mailto:nayeli@promo.com.mx">
                            <i className="now-ui-icons ui-1_email-85" style={{paddingRight: '6px'}}></i>
                            nayeli@promo.com.mx
                            </a>
                          </p>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                          <img className="img rounded" src={seller1Icon} height="200px" />
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Yolanda de la Torre</CardTitle>
                          <h6 className="category">Ventas</h6>
                          <p className="card-description">
                            Licenciada en Psicología.
                            <br/>
                            <a className="mail-to" href="mailto:ventas@promo.com.mx">
                            <i className="now-ui-icons ui-1_email-85" style={{paddingRight: '6px'}}></i>
                            ventas@promo.com.mx
                            </a>
                          </p>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
                <Col className="ml-auto mr-auto" lg="7" xl="6">
                  <Card className="card-profile card-plain">
                    <Row>
                      <Col md="5">
                        <div className="card-image">
                          <img className="img rounded" src={seller2Icon} height="200px" />
                        </div>
                      </Col>
                      <Col md="7">
                        <CardBody>
                          <CardTitle tag="h4">Joaquin Venegas</CardTitle>
                          <h6 className="category">Ventas</h6>
                          <p className="card-description">
                            Mtro. En Arqueología.
                            <br/>
                            <a className="mail-to" href="mailto:luis@promo.com.mx">
                            <i className="now-ui-icons ui-1_email-85" style={{paddingRight: '6px'}}></i>
                            ventas@promo.com.mx
                            </a>
                          </p>
                        </CardBody>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          {dGlobal.isDevelopment && 
            <>
            
          <div className="about-contact">
            <Container>
              <Row>
                <Col className="mr-auto ml-auto" md="8">
                  <h2 className="text-center title">Interesado en colaborar con nosotros?</h2>
                  <h4 className="text-center description">
                     Contáctenos y haganos saber su propuesta de colaboración, especifique brevemente los detalles
                    de su experiencia, servicio y/o producto. y con gusto le responderemos a la brevedad posible.
                  </h4>
                  <Form className="contact-form">
                    <Row>
                      <Col md="4">
                        <label>First name</label>
                        <InputGroup
                          className={firstFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons users_circle-08"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="firstname"
                            placeholder="First Name..."
                            type="text"
                            onFocus={() => setFirstFocus(true)}
                            onBlur={() => setFirstFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="4">
                        <label>Your email</label>
                        <InputGroup
                          className={emailFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            autoComplete="email"
                            placeholder="Email Name..."
                            type="text"
                            onFocus={() => setEmailFocus(true)}
                            onBlur={() => setEmailFocus(false)}
                          ></Input>
                        </InputGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label className="control-label">Speciality</label>
                          <Select
                            className="react-select react-select-primary"
                            onChange={value => setSpecialitySelect(value)}
                            classNamePrefix="react-select"
                            placeholder="Speciality"
                            value={specialitySelect}
                            name=""
                            options={[
                              {
                                value: "1",
                                label: "I'm a Designer"
                              },
                              { value: "2", label: "I'm a Developer" },
                              { value: "3", label: "I'm a Hero" }
                            ]}
                          ></Select>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col className="ml-auto mr-auto text-center" md="4">
                        <Button
                          className="btn-round mt-4"
                          color="info"
                          size="lg"
                        >
                          Let's talk
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Container>
          </div>
          
          </>
          }
          
          
        </div>
        
      </div>
    </>
  );
}

export default AboutUs;
