import React, { useState, useCallback, useRef } from "react";

// reactstrap components
import { Card, Container, Row, Col, Button, ButtonGroup, Input } from "reactstrap";
import Switch from "react-bootstrap-switch";

import 'react-dropzone-uploader/dist/styles.css';
import Dropzone from 'react-dropzone-uploader';

import {dGlobal} from "functions/DGlobal";
import { useLocalStorage } from "components/useLocalStorage";


function EditOfertasSlider() {
  
  const [GTotalOfertas, setGTotalOfertas] = useLocalStorage("GTotalOfertas", 1);
  const [nOfertas, setNOfertas] = useState([]);
  const [lastUpdate, setLastUpdate] = useState(new Date().getTime());
  
  React.useEffect(() => {
      
    updateGTotalOfertas(GTotalOfertas);
    
    return () => {
        
    }

  }, []);
  
  const onAddGTotalOfertas = () => {
     
     let t = parseInt(GTotalOfertas) + 1;
     
     console.log('onAddGTotalOfertas', t); 
     
     setGTotalOfertas(t);
     updateGTotalOfertas(t);
  };
  
  const onSubGTotalOfertas = () => {
     
     let t = parseInt(GTotalOfertas) - 1;
     
     console.log('onSubGTotalOfertas', t); 
     
     setGTotalOfertas(t);
     updateGTotalOfertas(t);
  };
  
  const updateGTotalOfertas = (max) => {
      
      let nOfertasItems = [];
      
      for (let i = 1; i <= max; i++) {
        nOfertasItems.push(i);
      }
      
      setNOfertas(nOfertasItems);
      
      const user_token = dGlobal.getStorage('user_token');
      
      fetch(process.env.REACT_APP_API_URL + "/api/admin/ofertas.config", {
	method: "POST" ,
	timeoutInterval: 15000,
	headers: {
            'Authorization': 'Bearer ' + user_token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            '_method': 'PUT',
            'total': max
        })
      })  
      .then(response => response.json())
      .then(responseJson => {
        console.log('responseJson', responseJson);

        if(!responseJson.error){

        }
        else {
            
        }
        
      })
      .catch(error => {
        console.error(error);
      });
  
  }
  
  const toast = (innerHTML) => {
    const el = document.getElementById('toast')
    el.innerHTML = innerHTML
    el.className = 'show'
    setTimeout(() => { el.className = el.className.replace('show', '') }, 3000)
  }
  
  const handleChangeStatus = ({ meta, remove }, status) => {
    if (status === 'headers_received') {
      setLastUpdate(new Date().getTime());  
      remove()
    } else if (status === 'aborted') {
    }
  }
  
  return (
    <>
      <div className="cd-section" id="features">

        <div className="features-4">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Edición de Ofertas</h2>
                <h4 className="description">
                  Configure el campo de la cantidad de ofertas visibles. luego arrastre las imagenes de ofertas correspondientes.
                </h4>
              </Col>
            </Row>
            <Row className="justify-content-center" style={{alignItems: "center"}}>
              <h6 className="tot-ofertas">Ofertas Visibles</h6>
              
                              <ButtonGroup>
                                <Button color="info" size="sm" onClick={() => onSubGTotalOfertas()}>
                                  <i className="now-ui-icons ui-1_simple-delete"></i>
                                </Button>
                                
                                <Input value={GTotalOfertas} onChange={(e) => setGTotalOfertas(e.target.value)} defaultValue={GTotalOfertas} placeholder="" type="text" pattern="[0-9]*" className="input-ofertas" ></Input>
                                <Button color="info" size="sm" onClick={() => onAddGTotalOfertas()}>
                                  <i className="now-ui-icons ui-1_simple-add"></i>
                                </Button>
                              </ButtonGroup>
            </Row>
            <Row>
            <Col>
              <Row className="justify-content-center" style={{alignItems: "center", margin: 24}}>
              <div id="toast"></div>
              </Row>
              {nOfertas.map(function(id, i){
                    return (
                       <Row style={{alignItems: "center"}}>
                       <Col className="ml-auto mr-auto text-center" md="1">
                         <h6 className="tot-ofertas">{id}</h6>
                       </Col>
                       <Col className="ml-auto mr-auto text-center" md="6">
                       <Dropzone
                         getUploadParams={({file}) => {
    
    const ismob = false;
    const user_token = dGlobal.getStorage('user_token');
    
    const body = new FormData()
    body.append('_method', 'PUT');
    body.append('isMobile', ismob);
    body.append('file', file);
    
    const headers =  {
            'Authorization': 'Bearer ' + user_token,
            'Accept': 'application/json',
    };
        
    return { url: `${process.env.REACT_APP_API_URL}/api/admin/ofertas/${id}`, body: body, headers: headers };
    
                  }}
                         onChangeStatus={handleChangeStatus}
                         maxFiles={1}
                         multiple={false}
                         canCancel={false}
                         inputContent="Escritorio"
                         styles={{
                            dropzone: { width: 540, height: 200, backgroundImage: `url(\"https://www.promo.com.mx/ofertas/oferta_${id}.jpg?t=${lastUpdate}\")`, backgroundSize: "contain" },
                            dropzoneActive: { borderColor: 'green' },
                         }}
                       />
                      </Col>
                      <Col className="ml-auto mr-auto text-center" md="3">
                       <Dropzone
                         getUploadParams={({file}) => {
    const ismob = true;
    const user_token = dGlobal.getStorage('user_token');
    
    const body = new FormData()
    body.append('_method', 'PUT');
    body.append('isMobile', ismob);
    body.append('file', file);
    
    const headers =  {
            'Authorization': 'Bearer ' + user_token,
            'Accept': 'application/json',
    };
        
    return { url: `${process.env.REACT_APP_API_URL}/api/admin/ofertas/${id}`, body: body, headers: headers };
    
                  }}
                         onChangeStatus={handleChangeStatus}
                         maxFiles={1}
                         multiple={false}
                         canCancel={false}
                         inputContent="Movil"
                         styles={{
                            dropzone: { width: 200, height: 200, backgroundImage: `url(\"https://www.promo.com.mx/ofertas/oferta_mob_${id}.jpg?t=${lastUpdate}\")`, backgroundSize: "contain" },
                            dropzoneActive: { borderColor: 'green' },
                         }}
                       />
                      </Col>
                      </Row>        
                      );
                  })}
                  </Col>
            </Row>
          </Container>
        </div>
        
        
        
        
      </div>
      
    </>
  );
}

export default EditOfertasSlider;
