/*eslint-disable*/
import React from "react";

function NucleoIcons() {
  const [open, setOpen] = React.useState(false);
  React.useEffect(() => {
    document.documentElement.classList.add("nucleo-icons-page-styles");
    return function cleanup() {
      document.documentElement.classList.remove("nucleo-icons-page-styles");
    };
  });
  return (
    <>
      <header>
        <h1>Nucleo Outline Web Font v2.9</h1>
        <p>
          <a href="https://nucleoapp.com/?ref=1712" target="_blank">
            nucleoapp.com
          </a>
        </p>
      </header>
      <div id="cd-nav">
        <a
          className={"cd-nav-trigger" + (open ? " menu-is-open" : "")}
          href="#pablo"
          id="cd-nav-trigger"
          onClick={e => {
            e.preventDefault();
            setOpen(!open);
          }}
        >
          Menu <span></span>
        </a>
        <nav>
          <ul id="cd-main-nav" className={open ? "is-visible" : ""}>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("arrows").scrollIntoView();
                }}
              >
                Arrows
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("business").scrollIntoView();
                }}
              >
                Business &amp; Finance
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("clothes").scrollIntoView();
                }}
              >
                Clothes &amp; Accessories
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("design").scrollIntoView();
                }}
              >
                Design &amp; Development
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("emoticons").scrollIntoView();
                }}
              >
                Emoticons
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("files").scrollIntoView();
                }}
              >
                Files &amp; Folders
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("medical").scrollIntoView();
                }}
              >
                Healthcare &amp; Medical
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("loaders").scrollIntoView();
                }}
              >
                Loaders
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("maps").scrollIntoView();
                }}
              >
                Maps &amp; Location
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("media").scrollIntoView();
                }}
              >
                Media
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("objects").scrollIntoView();
                }}
              >
                Objects
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("school").scrollIntoView();
                }}
              >
                School &amp; Education
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("shopping").scrollIntoView();
                }}
              >
                Shopping
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("sport").scrollIntoView();
                }}
              >
                Sport
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("technology").scrollIntoView();
                }}
              >
                Technology
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("text").scrollIntoView();
                }}
              >
                Text Editing
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("gestures").scrollIntoView();
                }}
              >
                Touch Gestures
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("transportation").scrollIntoView();
                }}
              >
                Transportation
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("travel").scrollIntoView();
                }}
              >
                Travel
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("interface").scrollIntoView();
                }}
              >
                User Interface
              </a>
            </li>
            <li>
              <a
                href="#pablo"
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                  document.getElementById("users").scrollIntoView();
                }}
              >
                Users
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <div id="icons-wrapper">
        <section id="arrows">
          <h2>Arrows</h2>
          <ul>
            <li>
              <i className="now-ui-icons arrows-1_cloud-download-93"></i>
              <p>arrows-1_cloud-download-93</p>
            </li>
            <li>
              <i className="now-ui-icons arrows-1_cloud-upload-94"></i>
              <p>arrows-1_cloud-upload-94</p>
            </li>
            <li>
              <i className="now-ui-icons arrows-1_minimal-down"></i>
              <p>arrows-1_minimal-down</p>
            </li>
            <li>
              <i className="now-ui-icons arrows-1_minimal-left"></i>
              <p>arrows-1_minimal-left</p>
            </li>
            <li>
              <i className="now-ui-icons arrows-1_minimal-right"></i>
              <p>arrows-1_minimal-right</p>
            </li>
            <li>
              <i className="now-ui-icons arrows-1_minimal-up"></i>
              <p>arrows-1_minimal-up</p>
            </li>
            <li>
              <i className="now-ui-icons arrows-1_refresh-69"></i>
              <p>arrows-1_refresh-69</p>
            </li>
            <li>
              <i className="now-ui-icons arrows-1_share-66"></i>
              <p>arrows-1_share-66</p>
            </li>
          </ul>
        </section>
        <section id="business">
          <h2>Business &amp; Finance</h2>
          <ul>
            <li>
              <i className="now-ui-icons business_badge"></i>
              <p>business_badge</p>
            </li>
            <li>
              <i className="now-ui-icons business_bank"></i>
              <p>business_bank</p>
            </li>
            <li>
              <i className="now-ui-icons business_briefcase-24"></i>
              <p>business_briefcase-24</p>
            </li>
            <li>
              <i className="now-ui-icons business_bulb-63"></i>
              <p>business_bulb-63</p>
            </li>
            <li>
              <i className="now-ui-icons business_chart-bar-32"></i>
              <p>business_chart-bar-32</p>
            </li>
            <li>
              <i className="now-ui-icons business_chart-pie-36"></i>
              <p>business_chart-pie-36</p>
            </li>
            <li>
              <i className="now-ui-icons business_globe"></i>
              <p>business_globe</p>
            </li>
            <li>
              <i className="now-ui-icons business_money-coins"></i>
              <p>business_money-coins</p>
            </li>
          </ul>
        </section>
        <section id="clothes">
          <h2>Clothes &amp; Accessories</h2>
          <ul>
            <li>
              <i className="now-ui-icons clothes_tie-bow"></i>
              <p>clothes_tie-bow</p>
            </li>
          </ul>
        </section>
        <section id="design">
          <h2>Design &amp; Development</h2>
          <ul>
            <li>
              <i className="now-ui-icons design_app"></i>
              <p>design_app</p>
            </li>
            <li>
              <i className="now-ui-icons design_bullet-list-67"></i>
              <p>design_bullet-list-67</p>
            </li>
            <li>
              <i className="now-ui-icons design_image"></i>
              <p>design_image</p>
            </li>
            <li>
              <i className="now-ui-icons design_palette"></i>
              <p>design_palette</p>
            </li>
            <li>
              <i className="now-ui-icons design_scissors"></i>
              <p>design_scissors</p>
            </li>
            <li>
              <i className="now-ui-icons design_vector"></i>
              <p>design_vector</p>
            </li>
            <li>
              <i className="now-ui-icons design-2_html5"></i>
              <p>design-2_html5</p>
            </li>
            <li>
              <i className="now-ui-icons design-2_ruler-pencil"></i>
              <p>design-2_ruler-pencil</p>
            </li>
          </ul>
        </section>
        <section id="emoticons">
          <h2>Emoticons</h2>
          <ul>
            <li>
              <i className="now-ui-icons emoticons_satisfied"></i>
              <p>emoticons_satisfied</p>
            </li>
          </ul>
        </section>
        <section id="files">
          <h2>Files &amp; Folders</h2>
          <ul>
            <li>
              <i className="now-ui-icons files_box"></i>
              <p>files_box</p>
            </li>
            <li>
              <i className="now-ui-icons files_paper"></i>
              <p>files_paper</p>
            </li>
            <li>
              <i className="now-ui-icons files_single-copy-04"></i>
              <p>files_single-copy-04</p>
            </li>
          </ul>
        </section>
        <section id="medical">
          <h2>Healthcare &amp; Medical</h2>
          <ul>
            <li>
              <i className="now-ui-icons health_ambulance"></i>
              <p>health_ambulance</p>
            </li>
          </ul>
        </section>
        <section id="loaders">
          <h2>Loaders</h2>
          <ul>
            <li>
              <i className="now-ui-icons loader_gear spin"></i>
              <p>loader_gear</p>
            </li>
            <li>
              <i className="now-ui-icons loader_refresh spin"></i>
              <p>loader_refresh</p>
            </li>
          </ul>
        </section>
        <section id="maps">
          <h2>Maps &amp; Location</h2>
          <ul>
            <li>
              <i className="now-ui-icons location_bookmark"></i>
              <p>location_bookmark</p>
            </li>
            <li>
              <i className="now-ui-icons location_compass-05"></i>
              <p>location_compass-05</p>
            </li>
            <li>
              <i className="now-ui-icons location_map-big"></i>
              <p>location_map-big</p>
            </li>
            <li>
              <i className="now-ui-icons location_pin"></i>
              <p>location_pin</p>
            </li>
            <li>
              <i className="now-ui-icons location_world"></i>
              <p>location_world</p>
            </li>
          </ul>
        </section>
        <section id="media">
          <h2>Media</h2>
          <ul>
            <li>
              <i className="now-ui-icons media-1_album"></i>
              <p>media-1_album</p>
            </li>
            <li>
              <i className="now-ui-icons media-1_button-pause"></i>
              <p>media-1_button-pause</p>
            </li>
            <li>
              <i className="now-ui-icons media-1_button-play"></i>
              <p>media-1_button-play</p>
            </li>
            <li>
              <i className="now-ui-icons media-1_button-power"></i>
              <p>media-1_button-power</p>
            </li>
            <li>
              <i className="now-ui-icons media-1_camera-compact"></i>
              <p>media-1_camera-compact</p>
            </li>
            <li>
              <i className="now-ui-icons media-2_note-03"></i>
              <p>media-2_note-03</p>
            </li>
            <li>
              <i className="now-ui-icons media-2_sound-wave"></i>
              <p>media-2_sound-wave</p>
            </li>
          </ul>
        </section>
        <section id="objects">
          <h2>Objects</h2>
          <ul>
            <li>
              <i className="now-ui-icons objects_diamond"></i>
              <p>objects_diamond</p>
            </li>
            <li>
              <i className="now-ui-icons objects_globe"></i>
              <p>objects_globe</p>
            </li>
            <li>
              <i className="now-ui-icons objects_key-25"></i>
              <p>objects_key-25</p>
            </li>
            <li>
              <i className="now-ui-icons objects_planet"></i>
              <p>objects_planet</p>
            </li>
            <li>
              <i className="now-ui-icons objects_spaceship"></i>
              <p>objects_spaceship</p>
            </li>
            <li>
              <i className="now-ui-icons objects_support-17"></i>
              <p>objects_support-17</p>
            </li>
            <li>
              <i className="now-ui-icons objects_umbrella-13"></i>
              <p>objects_umbrella-13</p>
            </li>
          </ul>
        </section>
        <section id="school">
          <h2>School &amp; Education</h2>
          <ul>
            <li>
              <i className="now-ui-icons education_agenda-bookmark"></i>
              <p>education_agenda-bookmark</p>
            </li>
            <li>
              <i className="now-ui-icons education_atom"></i>
              <p>education_atom</p>
            </li>
            <li>
              <i className="now-ui-icons education_glasses"></i>
              <p>education_glasses</p>
            </li>
            <li>
              <i className="now-ui-icons education_hat"></i>
              <p>education_hat</p>
            </li>
            <li>
              <i className="now-ui-icons education_paper"></i>
              <p>education_paper</p>
            </li>
          </ul>
        </section>
        <section id="shopping">
          <h2>Shopping</h2>
          <ul>
            <li>
              <i className="now-ui-icons shopping_bag-16"></i>
              <p>shopping_bag-16</p>
            </li>
            <li>
              <i className="now-ui-icons shopping_basket"></i>
              <p>shopping_basket</p>
            </li>
            <li>
              <i className="now-ui-icons shopping_box"></i>
              <p>shopping_box</p>
            </li>
            <li>
              <i className="now-ui-icons shopping_cart-simple"></i>
              <p>shopping_cart-simple</p>
            </li>
            <li>
              <i className="now-ui-icons shopping_credit-card"></i>
              <p>shopping_credit-card</p>
            </li>
            <li>
              <i className="now-ui-icons shopping_delivery-fast"></i>
              <p>shopping_delivery-fast</p>
            </li>
            <li>
              <i className="now-ui-icons shopping_shop"></i>
              <p>shopping_shop</p>
            </li>
            <li>
              <i className="now-ui-icons shopping_tag-content"></i>
              <p>shopping_tag-content</p>
            </li>
          </ul>
        </section>
        <section id="sport">
          <h2>Sport</h2>
          <ul>
            <li>
              <i className="now-ui-icons sport_trophy"></i>
              <p>sport_trophy</p>
            </li>
            <li>
              <i className="now-ui-icons sport_user-run"></i>
              <p>sport_user-run</p>
            </li>
          </ul>
        </section>
        <section id="technology">
          <h2>Technology</h2>
          <ul>
            <li>
              <i className="now-ui-icons tech_controller-modern"></i>
              <p>tech_controller-modern</p>
            </li>
            <li>
              <i className="now-ui-icons tech_headphones"></i>
              <p>tech_headphones</p>
            </li>
            <li>
              <i className="now-ui-icons tech_laptop"></i>
              <p>tech_laptop</p>
            </li>
            <li>
              <i className="now-ui-icons tech_mobile"></i>
              <p>tech_mobile</p>
            </li>
            <li>
              <i className="now-ui-icons tech_tablet"></i>
              <p>tech_tablet</p>
            </li>
            <li>
              <i className="now-ui-icons tech_tv"></i>
              <p>tech_tv</p>
            </li>
            <li>
              <i className="now-ui-icons tech_watch-time"></i>
              <p>tech_watch-time</p>
            </li>
          </ul>
        </section>
        <section id="text">
          <h2>Text Editing</h2>
          <ul>
            <li>
              <i className="now-ui-icons text_align-center"></i>
              <p>text_align-center</p>
            </li>
            <li>
              <i className="now-ui-icons text_align-left"></i>
              <p>text_align-left</p>
            </li>
            <li>
              <i className="now-ui-icons text_bold"></i>
              <p>text_bold</p>
            </li>
            <li>
              <i className="now-ui-icons text_caps-small"></i>
              <p>text_caps-small</p>
            </li>
          </ul>
        </section>
        <section id="gestures">
          <h2>Touch Gestures</h2>
          <ul>
            <li>
              <i className="now-ui-icons gestures_tap-01"></i>
              <p>gestures_tap-01</p>
            </li>
          </ul>
        </section>
        <section id="transportation">
          <h2>Transportation</h2>
          <ul>
            <li>
              <i className="now-ui-icons transportation_air-baloon"></i>
              <p>transportation_air-baloon</p>
            </li>
            <li>
              <i className="now-ui-icons transportation_bus-front-12"></i>
              <p>transportation_bus-front-12</p>
            </li>
          </ul>
        </section>
        <section id="travel">
          <h2>Travel</h2>
          <ul>
            <li>
              <i className="now-ui-icons travel_info"></i>
              <p>travel_info</p>
            </li>
            <li>
              <i className="now-ui-icons travel_istanbul"></i>
              <p>travel_istanbul</p>
            </li>
          </ul>
        </section>
        <section id="interface">
          <h2>User Interface</h2>
          <ul>
            <li>
              <i className="now-ui-icons ui-1_bell-53"></i>
              <p>ui-1_bell-53</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_calendar-60"></i>
              <p>ui-1_calendar-60</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_check"></i>
              <p>ui-1_check</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_lock-circle-open"></i>
              <p>ui-1_lock-circle-open</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_send"></i>
              <p>ui-1_send</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_settings-gear-63"></i>
              <p>ui-1_settings-gear-63</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_simple-add"></i>
              <p>ui-1_simple-add</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_simple-delete"></i>
              <p>ui-1_simple-delete</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_simple-remove"></i>
              <p>ui-1_simple-remove</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_zoom-bold"></i>
              <p>ui-1_zoom-bold</p>
            </li>
            <li>
              <i className="now-ui-icons ui-2_chat-round"></i>
              <p>ui-2_chat-round</p>
            </li>
            <li>
              <i className="now-ui-icons ui-2_favourite-28"></i>
              <p>ui-2_favourite-28</p>
            </li>
            <li>
              <i className="now-ui-icons ui-2_like"></i>
              <p>ui-2_like</p>
            </li>
            <li>
              <i className="now-ui-icons ui-2_settings-90"></i>
              <p>ui-2_settings-90</p>
            </li>
            <li>
              <i className="now-ui-icons ui-2_time-alarm"></i>
              <p>ui-2_time-alarm</p>
            </li>
            <li>
              <i className="now-ui-icons ui-1_email-85"></i>
              <p>ui-1_email-85</p>
            </li>
          </ul>
        </section>
        <section id="users">
          <h2>Users</h2>
          <ul>
            <li>
              <i className="now-ui-icons users_circle-08"></i>
              <p>users_circle-08</p>
            </li>
            <li>
              <i className="now-ui-icons users_single-02"></i>
              <p>users_single-02</p>
            </li>
          </ul>
        </section>
      </div>
    </>
  );
}

export default NucleoIcons;
