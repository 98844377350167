import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// reactstrap components
import {
  Alert,  
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import ReCAPTCHA from "react-google-recaptcha";
import validator from 'validator';

import BasePageView from "views/BasePageView.js";
import ModalOk from "components/ModalOk";


const MapWrapper = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap
      defaultZoom={16}
      defaultCenter={{ lat: 20.644497798914784, lng: -103.38426686269737 }}
      defaultOptions={{
        scrollwheel: false,
        styles: [
          {
            featureType: "water",
            elementType: "geometry",
            stylers: [{ color: "#e9e9e9" }, { lightness: 17 }]
          },
          {
            featureType: "landscape",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 20 }]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.fill",
            stylers: [{ color: "#ffffff" }, { lightness: 17 }]
          },
          {
            featureType: "road.highway",
            elementType: "geometry.stroke",
            stylers: [{ color: "#ffffff" }, { lightness: 29 }, { weight: 0.2 }]
          },
          {
            featureType: "road.arterial",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 18 }]
          },
          {
            featureType: "road.local",
            elementType: "geometry",
            stylers: [{ color: "#ffffff" }, { lightness: 16 }]
          },
          {
            featureType: "poi",
            elementType: "geometry",
            stylers: [{ color: "#f5f5f5" }, { lightness: 21 }]
          },
          {
            featureType: "poi.park",
            elementType: "geometry",
            stylers: [{ color: "#dedede" }, { lightness: 21 }]
          },
          {
            elementType: "labels.text.stroke",
            stylers: [
              { visibility: "on" },
              { color: "#ffffff" },
              { lightness: 16 }
            ]
          },
          {
            elementType: "labels.text.fill",
            stylers: [
              { saturation: 36 },
              { color: "#333333" },
              { lightness: 40 }
            ]
          },
          { elementType: "labels.icon", stylers: [{ visibility: "off" }] },
          {
            featureType: "transit",
            elementType: "geometry",
            stylers: [{ color: "#f2f2f2" }, { lightness: 19 }]
          },
          {
            featureType: "administrative",
            elementType: "geometry.fill",
            stylers: [{ color: "#fefefe" }, { lightness: 20 }]
          },
          {
            featureType: "administrative",
            elementType: "geometry.stroke",
            stylers: [{ color: "#fefefe" }, { lightness: 17 }, { weight: 1.2 }]
          }
        ]
      }}
    >
    
      <Marker position={{ lat: 20.644497798914784, lng: -103.38426686269737 }} />
    </GoogleMap>
  ))
);

function ContactoView() {
  const [first1Focus, setFirst1Focus] = React.useState(false);
  const [last1Focus, setLast1Focus] = React.useState(false);
  const [email1Focus, setEmail1Focus] = React.useState(false);
  const [first2Focus, setFirst2Focus] = React.useState(false);
  const [email2Focus, setEmail2Focus] = React.useState(false);
  const [formValid, setFormValid] = React.useState(false);
  const [msgInvalid, setMsgInvalid] = React.useState('');
  
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const messageRef = React.useRef();
  const recaptchaRef = React.useRef();
  const refForm =   React.useRef();
  const refModalOk =  React.useRef();
  
  
  const onInputChange = () => {
      setFormValid(false);
  };
  
  const onSubmit = () => {
      
    if(nameRef.current.value == ''){
        setMsgInvalid('Se requiere el campo: Nombre');
        setFormValid(true);
        return;
    }
    
    console.log('emailRef.current.value', emailRef.current.value, validator.isEmail(emailRef.current.value));
    
    if(!validator.isEmail(emailRef.current.value)) {
        setMsgInvalid('Se requiere el campo: Correo Electrónico');
        setFormValid(true);
        return;
    }
    
    if(messageRef.current.value == ''){
        setMsgInvalid('Se requiere el campo: Mensaje');
        setFormValid(true);
        return;
    }
    
    const recaptchaValue = recaptchaRef.current.getValue();
    
    if(recaptchaValue === '' || recaptchaValue === null){
        setMsgInvalid('Se requiere validar el campo: "No soy un robot"');
        setFormValid(true);
        return;
    }
    
    doPost();
    
  };
  
  const onRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    
    if(value === null){
        console.log("Captcha is null");
        
    }
    
    setFormValid(false);
    
  };
  
  const doPost = () => {
      
    console.log('doPost', refForm.current);  
      
    let formData = new FormData(refForm.current);
    
    fetch(process.env.REACT_APP_API_URL + "/api/user/contactus", {
	method: "POST" ,
	timeoutInterval: 15000,
	body: formData
    })  
    .then(response => response.json())
    .then(responseJson => {
        console.log('responseJson', responseJson);

        nameRef.current.value = '';
        emailRef.current.value = '';
        messageRef.current.value = '';
        
        refModalOk.current.open({message: 'Se ha enviado el mensaje de forma exitosa.', title: 'Envío de mensaje'});
        
    })
    .catch(error => {
        console.error(error);
    });
   
  };

  return (
    <BasePageView>    
      <div className="cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/contact1.png") + ")"
          }}
        >
          <Container>
            <Row>
              <Col md="5">
                <h2 className="title">Contáctanos</h2>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons business_globe"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Horario de atención</h4>
                    <p className="description">
                      Lunes - Viernes <br></br>
                      10:00 am - 05:00 pm<br></br>
                      horario corrido
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons location_pin"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Domicilio de oficina</h4>
                    <p className="description">
                      Av. Cruz del Sur 2731,   <br></br>
                      Jardines de la Cruz, C.P. 44950 <br></br>
                      Guadalajara, Jalisco, México.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons tech_mobile"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Atención Telefónica</h4>
                    <p className="description">
                      (+52) 333-563-2090<br></br>
                      (+52) 333-563-2091<br></br>
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="fab fa-whatsapp"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">WhatsApp</h4>
                    <p className="description">
                      (+52) 332-367-4059<br></br>
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-1_email-85"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Correo electrónico</h4>
                    <p className="description">
                      ventas@promo.com.mx
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="5">
                <h4 className="description">
                  ¿Necesitas hablar con nosotros?<br />
                  
                  En Promo estamos para servirle, envíenos sus comentarios y dudas.
                  
                </h4>
                <Card className="card-contact card-raised">
                  <form ref={refForm} action="" id="contact-form1" role="form" >
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Formulario de contacto</CardTitle>
                    </CardHeader>
                    <CardBody>
                          <label>Nombre</label>
                          <InputGroup
                            className={first1Focus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons users_circle-08"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              innerRef={nameRef}
                              aria-label="First Name..."
                              autoComplete="given-name"
                              placeholder="Nombre..."
                              type="text"
                              onFocus={() => setFirst1Focus(true)}
                              onBlur={() => setFirst1Focus(false)}
                              name="name"
                              onChange={() => onInputChange()}
                            ></Input>
                          </InputGroup>
                        
                      
                      <FormGroup>
                        <label>Correo Electrónico</label>
                        <InputGroup
                          className={email1Focus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={emailRef}
                            autoComplete="email"
                            placeholder="correo electrónico..."
                            type="email"
                            onFocus={() => setEmail1Focus(true)}
                            onBlur={() => setEmail1Focus(false)}
                            name="email"
                            onChange={() => onInputChange()}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      <FormGroup>
                        <label>Mensaje</label>
                        <Input
                          innerRef={messageRef}
                          placeholder="Escriba aquí su mensaje."
                          id="message"
                          name="message"
                          rows="6"
                          type="textarea"
                          onChange={() => onInputChange()}
                        ></Input>
                      </FormGroup>
                      <Row>
                        <Col className="gg-recaptcha" md="12">
                          <ReCAPTCHA hl="es-419" size="normal" ref={recaptchaRef} sitekey="6Lf2qsscAAAAAITG4kKb4nmwLU27XjAALhpL6rkV" onChange={onRecaptchaChange}  />
                        </Col>
                      </Row>
                      
                      <Alert color="warning" isOpen={formValid}>
          <Container>
            <div className="alert-icon">
              <i className="now-ui-icons ui-1_bell-53"></i>
            </div>
            <strong>Atención!</strong> {msgInvalid}
            <button
              type="button"
              className="close"
              onClick={() => setFormValid(false)}
            >
              <span aria-hidden="true">
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </Container>
        </Alert>
                      <Row>
                        <Col md="12">
                          <Button
                            className="btn-round pull-right"
                            color="info"
                            onClick={() => onSubmit()}
                          >
                            Enviar Mensaje
                          </Button>
                        </Col>
                      </Row>
                    </CardBody>
                  </form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <MapWrapper 
          googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDgnPpVUccKW3jASUqNCng6e8wJQaSDZyo"
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={<div style={{ height: `600px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
         />
      </div>
      <ModalOk id="c4" title="Información" ref={refModalOk} />
    </BasePageView>
    
  );
}

export default ContactoView;
