/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function Projects() {
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <div className="cd-section" id="projects">
        <div className="projects-2">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h6 className="text-muted">Sección</h6>
                <h2 className="title">Título de Sección 6</h2>
                <h5 className="description">
                  Descripción de sección Descripción de sección Descripción de sección Descripción de sección Descripción de sección 
                  Descripción de sección Descripción de sección.
                </h5>
                <div className="section-space"></div>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <Card className="card-plain">
                  <a
                    href="/promo"
                    target="_blank"
                  >
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img-raised rounded"
                        src="https://s3.amazonaws.com/creativetim_bucket/products/170/original/opt_nuk_react_thumbnail.jpg"
                      ></img>
                    </div>
                  </a>
                  <CardBody>
                    <a
                      href="https://www.creative-tim.com/product/now-ui-kit-react?ref=nuk-pro-react-sections"
                      target="_blank"
                    >
                      <CardTitle tag="h4">Título Imagen</CardTitle>
                    </a>
                    <h6 className="category text-info">Subtiítulo Imagen</h6>
                    <p className="card-description">
                      Descripción imagen Descripción imagen Descripción imagen Descripción imagen 
                      Descripción imagen Descripción imagen Descripción imagen Descripción imagen 
                      Descripción imagen.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain">
                  <a
                    href="/promo"
                    target="_blank"
                  >
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img-raised rounded"
                        src="https://s3.amazonaws.com/creativetim_bucket/products/155/original/opt_adp_react_thumbnail.jpg?1554905507"
                      ></img>
                    </div>
                  </a>
                  <CardBody>
                    <a
                      href="/promo"
                      target="_blank"
                    >
                      <CardTitle tag="h4">Título Imagen</CardTitle>
                    </a>
                    <h6 className="category text-info">Subtiítulo Imagen</h6>
                    <p className="card-description">
                      Descripción imagen Descripción imagen Descripción imagen Descripción imagen 
                      Descripción imagen Descripción imagen Descripción imagen Descripción imagen 
                      Descripción imagen.
                    </p>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-plain">
                  <a
                    href="/promo"
                    target="_blank"
                  >
                    <div className="card-image">
                      <img
                        alt="..."
                        className="img-raised rounded"
                        src="https://s3.amazonaws.com/creativetim_bucket/products/168/original/opt_pkp_react_thumbnail.jpg"
                      ></img>
                    </div>
                  </a>
                  <CardBody>
                    <a
                      href="/promo"
                      target="_blank"
                    >
                      <CardTitle tag="h4">Título Imagen</CardTitle>
                    </a>
                    <h6 className="category text-info">Subtiítulo Imagen</h6>
                    <p className="card-description">
                      Descripción imagen Descripción imagen Descripción imagen Descripción imagen 
                      Descripción imagen Descripción imagen Descripción imagen Descripción imagen 
                      Descripción imagen.
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
              </div>
    </>
  );
}

export default Projects;
