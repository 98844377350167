/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  NavLink,
  Container,
  Row,
  Col
} from "reactstrap";

import { Link, useNavigate } from "react-router-dom";

import {dGlobal} from "functions/DGlobal";

import infoIcon from 'assets/img/info_icon.svg';

function Footers() {
    
  const navigate = useNavigate();
  
  return (
    <>
      
      <div className="section section-footers" data-background-color="gray">
        <Container>
          <div className="title">
            
          </div>
        </Container>
        <div id="footer-areas">
          
          
          <br></br>
          <br></br>
          <footer className="footer footer-big" data-background-color="black">
            <Container>
              <div className="content">
                <Row>
                  <Col md="2">
                    <h5>Políticas</h5>
                    <ul className="links-vertical">
                      <li>
                        <a
                          className="text-muted"
                          onClick={() => navigate("/politicas")}
                        >
                          Generales
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          onClick={() => navigate("/politicas#devoluciones")}
                        >
                          Devoluciones
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          onClick={() => navigate("/politicas#garantias")}
                        >
                          Garantías
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          onClick={() => navigate("/politicas#envios")}
                        >
                          Envíos
                        </a>
                      </li>
                    </ul>
                  </Col>
                  <Col md="2">
                    <h5>Datos</h5>
                    <ul className="links-vertical">
                      <li>
                        <a
                          className="text-muted"
                          onClick={() => navigate("/datos#bancarios")}
                        >
                          Cuentas Bancarias
                        </a>
                      </li>
                      
                      <li>
                        <a
                          className="text-muted"
                          
                          onClick={() => navigate("/facturacion")}
                        >
                          Facturación Electrónica
                        </a>
                      </li>
                      
                      <li>
                        <a
                          className="text-muted"
                          
                          onClick={() => navigate("/privacidad")}
                        >
                          Avíso de privacidad
                        </a>
                      </li>
                      {dGlobal.isDevelopment && 
                    <>
                      <li>
                        <a
                          className="text-muted"
                          
                          onClick={e => e.preventDefault()}
                        >
                          Opción 3
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          
                          onClick={e => e.preventDefault()}
                        >
                          Opción 4
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          
                          onClick={e => e.preventDefault()}
                        >
                          Opción 5
                        </a>
                      </li>
                      <li>
                        <a
                          className="text-muted"
                          
                          onClick={e => e.preventDefault()}
                        >
                          Opción 4
                        </a>
                      </li>
                        </>
                  }
                    </ul>
                  </Col>
                  <Col md="4">
                    <h5>Recomendaciones</h5>
                    <div className="social-feed">
                      <div className="feed-line">
                        
                      <a className="text-muted"
                        
                          onClick={() => navigate("/blogpost1")}
                        >
                        <img className="svg-icon" src={infoIcon} height="20px" />
                        
                        <p>
                          Artículos Promocionales Como Estrategia de Promoción.
                        </p>
                        </a>
                      </div>
                      <div className="feed-line">
                      <a className="text-muted"
                          onClick={() => navigate("/blogpost2")}
                        >
                        <img className="svg-icon" src={infoIcon} height="20px" />
                        <p>
                          La importancia de los regalos publicitarios.
                        </p>
                      </a>  
                      </div>
                      <div className="feed-line">
                      <a className="text-muted"
                          onClick={() => navigate("/blogpost3")}
                        >
                        <img className="svg-icon" src={infoIcon} height="20px" />
                        <p>
                          ¿Por qué optar por Artículos promocionales?.
                        </p>
                        </a>
                      </div>
                    </div>
                  </Col>
                  
                  <Col md="4">
                    <h5>Siguenos</h5>
                    <ul className="social-buttons">
                      <li>
                        <Button
                          className="btn-icon btn-neutral btn-round mr-1"
                          color="twitter"
                          href="https://twitter.com/promo_articulo?s=21"
                          target="_blank"
                        >
                          <i className="fab fa-twitter"></i>
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="btn-icon btn-neutral btn-round mr-1"
                          color="facebook"
                          href="https://www.facebook.com/Promo.Ofertas"
                          target="_blank"
                        >
                          <i className="fab fa-facebook-square"></i>
                        </Button>
                      </li>
                      <li>
                        <Button
                          className="btn-icon btn-neutral btn-round"
                          color="instagram"
                          href="https://instagram.com/promo_articulos?utm_medium=copy_link"
                          target="_blank"
                        >
                          <i className="fab fa-instagram"></i>
                        </Button>
                      </li>
                    </ul>
                    
                    <h5>
                      <small>Algunos números</small>
                    </h5>
                    <h5>
                      +{(new Date().getFullYear()) - 2001}{" "}
                      <small className="text-muted">Años de Historia</small>
                    </h5>
                    <h5>
                      +2000{" "}
                      <small className="text-muted">Clientes Diferentes</small>
                    </h5>
                    <h5>
                      +5'250,000{" "}
                      <small className="text-muted">Piezas Vendidas</small>
                    </h5>
                    
                    
                  </Col>
                </Row>
              </div>
              
              <hr></hr>
              <ul className="pull-left">
                <li>
                  <a onClick={() => navigate("/")} >
                    Inicio
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate("/productos")}>
                    Productos y Servicios
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate("/catalogo")}>
                    Catálogos
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate("/nosotros")}>
                    Nosotros
                  </a>
                </li>
                <li>
                  <a onClick={() => navigate("/contacto")}>
                    Contácto
                  </a>
                </li>
              </ul>
              <div className="copyright pull-right">
                Copyright © {new Date().getFullYear()} Derechos Reservados Promo.com.mx
              </div>
            </Container>
          </footer>
          
        </div>
      </div>
    </>
  );
}

export default Footers;
