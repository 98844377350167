import React from "react";


import BasePageView from "views/BasePageView.js";

import Politicas from "components/Politicas.js";


function PoliticasView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Politicas />
      </div>
      </BasePageView>
  );
}

export default PoliticasView;
