import React from "react";


import BasePageView from "views/BasePageView.js";

import Products from "components/Products.js";


function ProductosView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Products />
        {/*<div className="wrapper-empty"></div>*/}
      </div>
      </BasePageView>
  );
}

export default ProductosView;
