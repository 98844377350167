import React, { useState, useCallback, useRef } from "react";

// reactstrap components
import { Card, Container, Row, Col, Button } from "reactstrap";

import establecimiento_logo_1 from 'assets/img/farmacias-del-ahorro.svg';
import establecimiento_logo_2 from 'assets/img/farmacia-guadalajara.svg';
import establecimiento_logo_3 from 'assets/img/7-eleven.svg';


function Datos() {
  
  return (
    <>
      <div className="cd-section" id="features">

        <div id="bancarios"></div>
        <div className="">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h4 className="title">Datos para Depósito Bancario o Transferencia</h4><br/><br/>
                <p className="politicas">
                  Si eligió como forma de pago deposito bancario o transferencia, estos son los datos que requiere: <br/><br/>

                  <b>Beneficiario: Luis Alberto Venegas Pérez.</b><br/>
                  <b>R.F.C. VEPL590601F72</b>
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h4 className="title">Depósito bancario</h4>
                <p className="politicas">
                  <b>Banco: BANAMEX</b><br/>
                  <b>Cuenta: 9551</b><br/>
                  <b>Sucursal: 4857</b><br/>
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h4 className="title">Transferencia Interbancaria</h4>
                <p className="politicas">
                  <b>Banco: Banamex</b><br/>
                  <b>CLABE: 002 320 48 5700 095 511</b><br/><br/>
                  Por favor envíe su comprobante de pago a nuestro correo: facturacion@promo.com.mx o a nuestro WhatsApp Con el nombre de su pedido.<br/><br/>
                  Favor de indicar si requerirá envío local o nacional.
                </p>
              </Col>
            </Row>
            
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h4 className="title">Depósitos en Establecimientos</h4>
                <p className="politicas">
                  Para su comodidad puede realizar su depósito en los siguientes establecimientos con los siguientes datos:<br/><br/>
                  
                  <b>Banco: BANAMEX</b><br/>
                  <b>Tarjeta: 5204 1651 2894 0792</b><br/><br/>
                  
                </p>
              </Col>
            </Row>
            <div className="social-line-white" style={{paddingBottom: 48}}>
            <Container>
              <Row className="justify-content-center social-line-white" >
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={establecimiento_logo_1} height="87px" />
                  </Row>
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={establecimiento_logo_2} height="87px" />
                </Row>
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={establecimiento_logo_3} height="87px" />
                </Row>  
                </Col>
              </Row>
            </Container>
          </div>


        </Container>
        </div>
        
        
      </div>
      
    </>
  );
}

export default Datos;
