import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardLink,
  CardTitle,
  Collapse,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";

// core components
import ProductPageHeader from "components/Headers/ProductPageHeader.js";

import shieldPromo from 'assets/img/shield-promo.svg' ;
import bordadoIcon from 'assets/img/bordado_icon.svg';
import sublimacionIcon from 'assets/img/sublimacion_icon.svg';
import laserIcon from 'assets/img/laser_icon.svg';
import serigrafiaIcon from 'assets/img/serigrafia_icon.svg';
import iconLogoWhite from '../assets/img/iconLogoWhite.svg';

const items = [
  {
    src: require("assets/img/pp-1.jpg"),
    altText: "",
    caption: ""
  },
  {
    src: require("assets/img/pp-2.jpg"),
    altText: "",
    caption: ""
  },
  {
    src: require("assets/img/pp-3.jpg"),
    altText: "",
    caption: ""
  },
  {
    src: require("assets/img/pp-4.jpg"),
    altText: "",
    caption: ""
  },
  {
    src: require("assets/img/pp-5.jpg"),
    altText: "",
    caption: ""
  }
  ,
  {
    src: require("assets/img/pp-6.jpg"),
    altText: "",
    caption: ""
  }
  ,
  {
    src: require("assets/img/pp-7.jpg"),
    altText: "",
    caption: ""
  }
  ,
  {
    src: require("assets/img/pp-8.jpg"),
    altText: "",
    caption: ""
  }
];

function Products() {
  // carousel states and functions
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };
  // collapse states and functions
  const [collapses, setCollapses] = React.useState([1]);
  const changeCollapse = collapse => {
    if (collapses.includes(collapse)) {
      setCollapses(collapses.filter(prop => prop !== collapse));
    } else {
      setCollapses([...collapses, collapse]);
    }
  };
  // select states and functions
  const [colorSelect, setColorSelect] = React.useState(null);
  const [sizeSelect, setSizeSelect] = React.useState(null);
  React.useEffect(() => {
    document.body.classList.add("product-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("product-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper">
        <ProductPageHeader />
        <div className="section">
          <Container>
            <Row>
              <Col md="5">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={items}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {items.map(item => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        <img
                          src={item.src}
                          alt={item.altText}
                          className="d-block img-raised"
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-left"></i>
                    </Button>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <Button
                      className="btn-icon btn-round"
                      color="info"
                      name="button"
                      size="sm"
                      type="button"
                    >
                      <i className="now-ui-icons arrows-1_minimal-right"></i>
                    </Button>
                  </a>
                </Carousel>
                
              </Col>
              <Col md="6">
              <h4 className="description text-center">
                Contamos con miles de artículos promociónales, como termos, llaveros, plumas, gorras, playeras, foto- botones, tazas, hasta los mejores artículos ejecutivos (agendas, portafolios, juegos de escritorio, etc.)
                <br />
                Las opciones son infinitas, podemos imprimir cualquier logotipo, fotografía o cualquier diseño que se desee en: plástico, metal, vidrio, piel o papel; y en casi cualquier superficie o textura.



              </h4>
              
              
            </Col>  
            </Row>
            
            <div className="features-6">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Servicios</h2>
                <h4 className="description">
                  En PROMO sabemos del potencial de los artículos promocionales para dar a conocer su empresa, marca e imagen, es por eso que brindamos una gran variedad de productos personalizados, como termos, libretas, plumas, tazas, estuches, llaveros, relojes, bolsas, mochilas, entre muchas opciones mas.
                  <br/><br/>    
                  Además, contamos con el servicio de personalización de productos, con lo que se puede individualizar cada uno de ellos para identificar al personal o a los clientes con el nombre y/o logotipo requerido.


                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-servicios">
                  <div className="icon icon-success">
                    <img src={laserIcon} height="96px" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Grabado láser</h4>
                    <p>
                      Consiste en eliminar capas del producto a través del rayo, llegando a determinada profundidad.
Se puede realizar en llaveros, termos, placas (15x15cms) agendas, etc.
                    </p>
                  </div>
                </div>
                <div className="info info-servicios">
                  <div className="icon icon-primary">
                    <img src={serigrafiaIcon} height="96px" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">Serigrafía</h5>
                    <p>
                      Técnica de impresión en la cual se transfiere el logotipo, texto o imagen, por medio de una mallade seda. Se puede imprimir desde 1 tinta hasta máximo 6.

                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="phone-container">
                  <img src={shieldPromo} height="220px" />
                </div>
              </Col>
              <Col md="4">
                <div className="info info-servicios">
                  <div className="icon icon-danger">
                    <img src={bordadoIcon} height="96px" />
                  </div>
                  <div className="description">
                    <h4 className="info-title">Bordado</h4>
                    <p>
                      Consiste en utilizar aguja e hilo y se plasma el logotipo o texto en prendas textiles, principalmente.
                      Gorras, chamarras, sudaderas, playeras cuello redondo o tipo polo.

                    </p>
                  </div>
                </div>
                <div className="info info-servicios">
                  <div className="icon icon-info">
                    <img src={sublimacionIcon} height="96px" />
                  </div>
                  <div className="description">
                    <h5 className="info-title">Sublimación</h5>
                    <p>
                      Técnica de impresión a todo color.
                      Productos sublimables: tazas, mouse pad, textiles, termos, etc. <br /><br /><br />

                    </p>
                  </div>
                </div>
                
                
              </Col>
            </Row>
          </Container>
        </div>
            <div className="features-4">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">3 razones por las que debes regalar artículos promocionales a tus clientes</h2>
                    <h4 className="description">
Está comprobado que estos regalos son una herramienta muy útil para el posicionamiento de
la marca dentro del mercado, así como para destacar entre la competencia.


                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Card
                      className="card-background card-raised"
                      data-background-color=""
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/pp-bg6.png") + ")"
                      }}
                    >
                      <div className="info">
                        <div className="icon icon-white">
                          <i className="now-ui-icons business_money-coins"></i>
                        </div>
                        <div className="description">
                        <h4 className="info-title">Aumenta la venta <br />de tus productos</h4>
                          <p>
                            El propósito de la publicidad es generar ingresos para la empresa y los productos
promocionales están diseñados especialmente para lograr ese objetivo.<br /><br />
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      className="card-background card-raised"
                      data-background-color=""
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/pp-bg7.png") + ")"
                      }}
                    >
                      <div className="info">
                        <div className="icon icon-white">
                          <img src={iconLogoWhite} height="48px" />
                        </div>
                        <div className="description">
                          <h4 className="info-title">Reconocimiento <br />de la marca</h4>
                          <p>
                            Suelen ser productos útiles en la
vida cotidiana, por lo que los clientes los usan y tienden a conservarlos. Esto hará que tu marca permanezca más tiempo en la memoria de sus clientes.
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card
                      className="card-background card-raised"
                      data-background-color=""
                      style={{
                        backgroundImage:
                          "url(" + require("assets/img/pp-bg8.png") + ")"
                      }}
                    >
                      <div className="info">
                        <div className="icon">
                          <i className="now-ui-icons ui-2_like"></i>
                        </div>
                        <div className="description">
                          <h4 className="info-title">Llegar a más gente  <br />con la mercadotecnia</h4>
                          <p>
                            Muchos pasan de mano en mano, por lo tanto, tu marca termina llegando a más personas que pueden estar interesadas en tus productos o servicios. <br /><br /> 
                          </p>
                        </div>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        </div>
        <div className="section features-5">
          <Container>
            <Row>
              <Col md="5">
                <h2 className="title"> En nuestro catálogo encontrará promocionales para:</h2>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons business_briefcase-24"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Altos ejecutivos / empleados</h4>
                    
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons users_single-02"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Clientes</h4>
                    
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons shopping_bag-16"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Mercado objetivo / probables clientes</h4>
                    
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons clothes_tie-bow"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Sociales / Familiares</h4>
                    
                  </div>
                </div>
              </Col>
              <Col md="7">
                <div className="tablet-container">
                  <img
                    alt="..."
                    src={require("assets/img/pp-bg8.png")}
                  ></img>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
        <div className="section features-5">
          <Container>
            <Row>
              <Col md="7">
                <div className="tablet-container">
                  <img
                    alt="..."
                    src={require("assets/img/pp-bg6.png")}
                  ></img>
                </div>
              </Col>
              <Col md="5">
                <h2 className="title"> Obsequios de temporada y festivos: </h2>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-1_bell-53"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Navideños</h4>
                    
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-1_calendar-60"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Día de las Madres / del Padre / del Niño / de La Secretaría / Del Maestro</h4>
                    
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons design-2_ruler-pencil"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Regreso a clases</h4>
                    
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-1_calendar-60"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Fiestas Patrias</h4>
                    
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons clothes_tie-bow"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Eventos sociales</h4>
                    
                  </div>
                </div>
              </Col>
              
            </Row>
          </Container>
        </div>
        
        
      </div>
    </>
  );
}

export default Products;
