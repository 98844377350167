import React from "react";


import BasePageView from "views/BasePageView.js";

import Privacidad from "components/Privacidad.js";


function PrivacidadView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Privacidad />
      </div>
      </BasePageView>
  );
}

export default PrivacidadView;
