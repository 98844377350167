import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef } from "react";
import type {Node} from 'react';

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

import {
  Button,
  CardHeader,
  CardBody,
  Collapse,
  FormGroup,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalFooter
} from "reactstrap";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  
} from "reactstrap";

import Lottie from 'react-lottie';
import animationData from 'assets/img/loading.json'

import ProgressBar from "@ramonak/react-progress-bar";

const ModalDownload = (props, ref): Node => {
  
  const [file, setFile] = useState(props.file);
  const [modalOpen, setModalOpen] = useState(false);
  const [isStoped, setIsStoped] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [progress, setProgress] = useState(0);
  const refModal =  useRef();
  
  const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    
    
  const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    };  
    
  React.useEffect(() => {
      
    console.log("ModalDownload mounted", props);
    
    return () => {
        console.log('ModalDownload  destroy');
    }

  }, []);
  
  useImperativeHandle(ref, () => ({
    // methods connected to `ref`
    open: (data) => { open(data) },
    close: () => { close() }
  }));
  
  const open = (data) => {
      
    setProgress(0);  
    setModalOpen(true);
    
  fetch(data.url)
  // Retrieve its body as ReadableStream
  .then(rs => {
      
    const totalLength = Number(rs.headers.get('content-length'));

    let bytesReceived = 0;
  
    const reader = rs.body.getReader();

    return new ReadableStream({
      async start(controller) {
        while (true) {
          const { done, value } = await reader.read();

          // When no more data needs to be consumed, break the reading
          if (done) {
            console.log('Fetch complete');  
            break;
          }
          
          bytesReceived += value.byteLength
          console.log(`received ${value.byteLength} bytes (${bytesReceived} bytes in total)`)
      
          console.log('Received_', bytesReceived, 100 / (totalLength / bytesReceived), totalLength);
      
          setProgress(parseInt(100 / (totalLength / bytesReceived)));
          
          // Enqueue the next data chunk into our target stream
          controller.enqueue(value);
        }

        // Close the stream
        controller.close();
        reader.releaseLock();
      }
    })
  })
  // Create a new response out of the stream
  .then(rs => new Response(rs))
  // Create an object URL for the response
  .then(response => response.blob())
  .then(blob => {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = data.name; // the filename you want
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    
    setModalOpen(false);
    
    
  })
  .catch(console.error);
    
  
  };
  
function consume(response) {
  const totalLength = Number(response.headers.get('content-length'));

  let bytesReceived = 0;
      
  let reader = response.body.getReader();
  
  return pump()
  function pump() {
    return reader.read().then(({done, value}) => {
      if (done) {
        return
      }
      bytesReceived += value.byteLength
      console.log(`received ${value.byteLength} bytes (${bytesReceived} bytes in total)`)
      
      console.log('Received_', bytesReceived, totalLength);
      
      return pump()
    })
  }
}


  
  const close = () => {
    setModalOpen(false);
  };
  
  return (
    <>
      <Modal
                isOpen={modalOpen}
                style={{
                    maxWidth: 500,
                    maxHeight: 280,
                    
                  }}
                  
                ref={refModal}  
                
                centered={true}
              >
                <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setModalOpen(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="title title-up">{props.title}</h4>
                </div>
                <div className="modal-body">
                <div className="progressbar-download">
                  <ProgressBar completed={progress} bgColor="#ec640b"/>
                  </div>
                  <Lottie options={defaultOptions}
              height={120}
              width={120}
              isStopped={isStoped}
              isPaused={isPaused} />
                
                </div>
                <ModalFooter>
                 
                </ModalFooter>
              </Modal>
              
    </>
  );
}

export default forwardRef(ModalDownload);
