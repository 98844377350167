import React from "react";
// react plugin used to create google maps
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
// reactstrap components
import {
  Alert,  
  Button,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  FormText,
  CustomInput
} from "reactstrap";

import Select from "react-select";

import ReCAPTCHA from "react-google-recaptcha";
import validator from 'validator';

import ModalOk from "components/ModalOk";



function Facturacion() {
  const usoData = [
                    { value: "1", label: "Adquisición de mercancia" },
                    { value: "2", label: "Gastos en General" }
                   ];
    
  const [first1Focus, setFirst1Focus] = React.useState(false);
  const [rfcFocus, setRFCFocus] = React.useState(false);
  const [phoneFocus, setPhoneFocus] = React.useState(false);
  const [email1Focus, setEmail1Focus] = React.useState(false);
  const [cpFocus, setCPFocus] = React.useState(false);
  const [usoFocus, setUsoFocus] = React.useState(false);
  const [ticketFocus, setTicketFocus] = React.useState(false);
  const [formValid, setFormValid] = React.useState(false);
  const [msgInvalid, setMsgInvalid] = React.useState('');
  const [usoSelect, setUsoSelect] = React.useState(usoData[0]);
  const [fileName, setFileName] = React.useState('');
  const [invalidFile, setInvalidFile] = React.useState('');
  
  const nameRef = React.useRef();
  const emailRef = React.useRef();
  const addressRef = React.useRef();
  
  const rfcRef = React.useRef();
  const phoneRef = React.useRef();
  const cpRef = React.useRef();
  const usoRef = React.useRef();
  const ticketRef = React.useRef();
  const recaptchaRef = React.useRef();
  const refForm =   React.useRef();
  const refModalOk =  React.useRef();
  
                      
  const onInputChange = () => {
      setFormValid(false);
  };
  
  const onSubmit = () => {
      
    if(validator.isEmpty(nameRef.current.value)){
        setMsgInvalid('Se requiere el campo: Razón Social');
        setFormValid(true);
        return;
    }
    
    if(validator.isEmpty(rfcRef.current.value)){
        setMsgInvalid('Se requiere el campo: RFC');
        setFormValid(true);
        return;
    }
    
    if(!validator.isEmail(emailRef.current.value)) {
        setMsgInvalid('Se requiere el campo: Correo Electrónico');
        setFormValid(true);
        return;
    }
    
    if(!validator.isMobilePhone(phoneRef.current.value)) {
        setMsgInvalid('Se requiere el campo: Teléfono');
        setFormValid(true);
        return;
    }

    if(!validator.isNumeric(cpRef.current.value)) {
        setMsgInvalid('Se requiere el campo: Código Postal');
        setFormValid(true);
        return;
    }
    
    if(validator.isEmpty(addressRef.current.value)){
        setMsgInvalid('Se requiere el campo: Domicilio');
        setFormValid(true);
        return;
    }
    
    if(!ticketRef.current.files.length) {
        setMsgInvalid('Se requiere el campo: Comprobante de pago');
        setFormValid(true);
        return;
    }
    
    
    
    const recaptchaValue = recaptchaRef.current.getValue();
    
    if(recaptchaValue === '' || recaptchaValue === null){
        setMsgInvalid('Se requiere validar el campo: "No soy un robot"');
        setFormValid(true);
        return;
    }
    
    doPost();
    
  };
  
  const onFileChange = ({target: {files}}) => {
      
      
    console.log('files', files);
    
    const cancel = !files.length;
    
    if (cancel) return;
    
    setFormValid(false);

    const [{ size, name }] = files;
    const maxSize = 5000000;
    
    console.log('maxSize', maxSize);

    if (size < maxSize) {
      setFileName(name);
      setInvalidFile(false);
    } else {
      setFileName('');
      setInvalidFile(true);
    }
      
  }
  
  const onRecaptchaChange = (value) => {
    console.log("Captcha value:", value);
    
    if(value === null){
        console.log("Captcha is null");
        
    }
    
    setFormValid(false);
    
  };
  
  const doPost = () => {
      
    console.log('doPost', refForm.current);  
      
    let formData = new FormData(refForm.current);

    fetch(process.env.REACT_APP_API_URL +  "/api/user/facturacion", {
	method: "POST" ,
	timeoutInterval: 15000,
	body: formData,
        headers: {
            'Origin': 'http://localhost:3000',
            
        },
        mode: 'cors',
    })  
    .then(response => response.json())
    .then(responseJson => {
        console.log('responseJson', responseJson);

        nameRef.current.value = '';
        emailRef.current.value = '';
        addressRef.current.value = '';
        rfcRef.current.value = '';;
        phoneRef.current.value = '';;
        cpRef.current.value = '';;
        //usoRef.current.value = '';;
        //ticketRef.current.value = '';;
        
        refModalOk.current.open({message: 'Se ha enviado la solicitud de forma exitosa.', title: 'Solicitud de factura'});
        
    })
    .catch(error => {
        console.error(error);
    });
   
  };

  return (
      <div className="cd-section" id="contact-us">
        <div
          className="contactus-1 section-image"
          style={{
            backgroundImage: "url(" + require("assets/img/contact1.png") + ")"
          }}
        >
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Facturación Electrónica</h2>
                <h4 className="description">
                  <br />
                  
                  En este formulario podrás solicitar tu factura electrónica, es necesario completes los campos e incluyas tu comprobante de pago en formato pdf o imagen jpg.<br /><br />
                  
                  
                  
                </h4>
                <Card className="card-contact card-raised">
                  <form ref={refForm} action="" id="contact-form1" role="form" >
                    <CardHeader className="text-center">
                      <CardTitle tag="h4">Formulario de contacto</CardTitle>
                    </CardHeader>
                    <CardBody>
                    <Row>
                      <Col className="ml-auto mr-auto" md="11">
                      
                          <label>Razón Social</label>
                          <InputGroup
                            className={first1Focus ? "input-group-focus" : ""}
                          >
                            <InputGroupAddon addonType="prepend">
                              <InputGroupText>
                                <i className="now-ui-icons location_bookmark"></i>
                              </InputGroupText>
                            </InputGroupAddon>
                            <Input
                              innerRef={nameRef}
                              aria-label="First Name..."
                              autoComplete="given-name"
                              placeholder="Razón Social..."
                              type="text"
                              onFocus={() => setFirst1Focus(true)}
                              onBlur={() => setFirst1Focus(false)}
                              name="name"
                              onChange={() => onInputChange()}
                            ></Input>
                          </InputGroup>
                      </Col>
                      </Row>
                      <Row>
                      <Col className="ml-auto mr-auto" md="5">
                      <FormGroup>
                        <label>RFC</label>
                        <InputGroup
                          className={rfcFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons education_paper"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={rfcRef}
                            autoComplete="rfc"
                            placeholder="RFC..."
                            type="text"
                            onFocus={() => setRFCFocus(true)}
                            onBlur={() => setRFCFocus(false)}
                            name="rfc"
                            onChange={() => onInputChange()}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      <Col className="ml-auto mr-auto" md="5">
                      <FormGroup>
                        <label>Correo Electrónico</label>
                        <InputGroup
                          className={email1Focus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons ui-1_email-85"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={emailRef}
                            autoComplete="email"
                            placeholder="correo electrónico..."
                            type="email"
                            onFocus={() => setEmail1Focus(true)}
                            onBlur={() => setEmail1Focus(false)}
                            name="email"
                            onChange={() => onInputChange()}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      </Row>
                      <Row>
                      <Col className="ml-auto mr-auto" md="5">
                      <FormGroup>
                        <label>Teléfono</label>
                        <InputGroup
                          className={phoneFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons tech_mobile"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={phoneRef}
                            autoComplete="phone"
                            placeholder="Teléfono..."
                            type="text"
                            onFocus={() => setPhoneFocus(true)}
                            onBlur={() => setPhoneFocus(false)}
                            name="phone"
                            onChange={() => onInputChange()}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      <Col className="ml-auto mr-auto" md="5">
                      <FormGroup>
                        <label>Código Postal</label>
                        <InputGroup
                          className={cpFocus ? "input-group-focus" : ""}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="now-ui-icons location_world"></i>
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            innerRef={cpRef}
                            autoComplete="cp"
                            placeholder="Código Postal..."
                            type="text"
                            onFocus={() => setCPFocus(true)}
                            onBlur={() => setCPFocus(false)}
                            name="cp"
                            onChange={() => onInputChange()}
                          ></Input>
                        </InputGroup>
                      </FormGroup>
                      </Col>
                      </Row>
                      <Row>
                      <Col className="ml-auto mr-auto" md="11">
                    
                      <FormGroup>
                        <label>Domicilio Fiscal</label>
                        <Input
                          innerRef={addressRef}
                          placeholder="Escriba aquí su domicilio fiscal."
                          id="address"
                          name="address"
                          rows="6"
                          type="textarea"
                          onChange={() => onInputChange()}
                        ></Input>
                      </FormGroup>
                      </Col>
                      </Row>
                      <Row>
                      <Col className="ml-auto mr-auto" md="5">
                      <FormGroup>
                        <label>Uso del CFDI</label>
                          <Select
                          innerRef={usoRef}
                      className="react-select react-select-info mt-2"
                      onChange={value => setUsoSelect(value)}
                      classNamePrefix="react-select"
                      placeholder="Single Select"
                      value={usoSelect}
                      name="uso"
                      options={usoData}
                      defaultValue={usoData[0]}
                    ></Select>
                        
                      </FormGroup>
                      </Col>
                      <Col className="ml-auto mr-auto" md="5">
                      <FormGroup>
                        <label for="ticketFile">Comprobante de pago (pdf, jpg)</label>
                        <CustomInput
                        innerRef={ticketRef}
          type="file"
          id="exampleCustomFileBrowser"
          name="ticket"
          label={fileName || 'Seleccione un archivo'}
          onChange={onFileChange}
          invalid={invalidFile} />
          
                        <FormText color="muted">
                        Haga clic para abrir la ventana de seleción de archivos y selecione el comprobante desde su dispositivo.
                        
                        </FormText>
                      </FormGroup>
                      </Col>
                      </Row>
                      <hr></hr>
                      <Row>
                        <Col className="ml-auto mr-auto" md="5">
                          <ReCAPTCHA hl="es-419" size="normal" ref={recaptchaRef} sitekey="6Lf2qsscAAAAAITG4kKb4nmwLU27XjAALhpL6rkV" onChange={onRecaptchaChange}  />
                        </Col>
                        <Col md="5">
                          <Button
                            className="btn-round pull-right"
                            color="info"
                            onClick={() => onSubmit()}
                          >
                            Solicitar Factura
                          </Button>
                        </Col>
                      </Row>
                      
                      <Alert color="warning" isOpen={formValid}>
          <Container>
            <div className="alert-icon">
              <i className="now-ui-icons ui-1_bell-53"></i>
            </div>
            <strong>Atención!</strong> {msgInvalid}
            <button
              type="button"
              className="close"
              onClick={() => setFormValid(false)}
            >
              <span aria-hidden="true">
                <i className="now-ui-icons ui-1_simple-remove"></i>
              </span>
            </button>
          </Container>
        </Alert>
                    </CardBody>
                  </form>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        <ModalOk id="c4" title="Información" ref={refModalOk} />
      </div>
      
    
  );
}

export default Facturacion;
