import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef } from "react";
import type {Node} from 'react';

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";
import {dGlobal} from "functions/DGlobal";

import {
  Button,
  CardHeader,
  CardBody,
  Collapse,
  FormGroup,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  
} from "reactstrap";


const ModalOk = (props, ref): Node => {
  
  const [modalOpen, setModalOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const refModal =  useRef();
  
    
  const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    };  
    
  React.useEffect(() => {
      
    console.log("ModalOk mounted", props);
    
    return () => {
        console.log('ModalOk  destroy');
    }

  }, []);
  
  useImperativeHandle(ref, () => ({
    // methods connected to `ref`
    open: (data) => { open(data) },
    close: () => { close() }
  }));
  
  const open = (data) => {
    
    setTitle(data.title);
    setMessage(data.message);    
    
    setModalOpen(true);
  
  };
  
  const close = () => {
    setModalOpen(false);
  };
  
  
  return (
    <>
      <Modal
                isOpen={modalOpen}
                style={{
                    maxWidth: 500,
                    maxHeight: 280,
                    
                  }}
                  
                ref={refModal}  
                
                centered={true}
              >
                <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setModalOpen(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="title title-up">{title}</h4>
                </div>
          <ModalBody className="modal-body-ok">
            {message}
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button color="primary" onClick={() => setModalOpen(false)} >Aceptar</Button>
          </ModalFooter>
              </Modal>
              
    </>
  );
}

export default forwardRef(ModalOk);
