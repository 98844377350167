import React from "react";

// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";

// core components

function Features() {
  return (
    <>
      <div className="cd-section" id="features">
        <div className="features-1">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Título Sección 1</h2>
                <h4 className="description">
                  Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-info">
                    <i className="now-ui-icons ui-2_chat-round"></i>
                  </div>
                  <h4 className="info-title">Social Conversations</h4>
                  <p className="description">
                    Gain access to the demographics, psychographics, and
                    location of unique people.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success">
                    <i className="now-ui-icons business_chart-pie-36"></i>
                  </div>
                  <h4 className="info-title">Analyze Performance</h4>
                  <p className="description">
                    Unify data from Facebook, Instagram, Twitter, LinkedIn, and
                    Youtube to gain rich insights.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-warning">
                    <i className="now-ui-icons design-2_ruler-pencil"></i>
                  </div>
                  <h4 className="info-title">Measure Conversions</h4>
                  <p className="description">
                    Track actions taken on your website, understand the impact
                    on your bottom line.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="features-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="title">Título Sección 2</h2>
                <h4 className="description">
                  Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-success icon-circle">
                    <i className="now-ui-icons objects_globe"></i>
                  </div>
                  <h4 className="info-title">Asset Management</h4>
                  <p className="description">
                    Cross-account dashboards provide sophisticated reporting for
                    enterprise.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-primary icon-circle">
                    <i className="now-ui-icons education_atom"></i>
                  </div>
                  <h4 className="info-title">Resource Utilization</h4>
                  <p className="description">
                    CloudCheckr provides summary and detailed usage statistics
                    for resources.
                  </p>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover">
                  <div className="icon icon-info icon-circle">
                    <i className="now-ui-icons tech_watch-time"></i>
                  </div>
                  <h4 className="info-title">Self-Healing</h4>
                  <p className="description">
                    CloudCheckr enables users to save money, time, and effort.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="features-5">
          <Container>
            <Row>
              <Col md="5">
                <h2 className="title">Título Sección 4</h2>
                <div className="info info-horizontal">
                  <div className="icon icon-info icon-circle">
                    <i className="now-ui-icons location_world"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Subtítulo 1</h4>
                    <p>
                      Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                        Parrafo de descripción de sección Parrafo de descripción de sección.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info icon-circle">
                    <i className="now-ui-icons sport_user-run"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Subtítulo 2</h4>
                    <p>
                      Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-info icon-circle">
                    <i className="now-ui-icons ui-2_time-alarm"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Subtítulo 3</h4>
                    <p>
                      Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="7">
                <div className="tablet-container">
                  <img
                    alt="..."
                    src={require("assets/img/agendas.png")}
                  ></img>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="features-6">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Título Sección 5</h2>
                <h4 className="description">
                  Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                </h4>
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-info">
                    <i className="now-ui-icons design-2_html5"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Subtítulo 1</h5>
                    <p>
                      Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-danger">
                    <i className="now-ui-icons design_palette"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Subtítulo 2</h4>
                    <p>
                      Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="phone-container">
                  <img alt="..." src={require("assets/img/plumas.png")}></img>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-horizontal">
                  <div className="icon icon-primary">
                    <i className="now-ui-icons design-2_ruler-pencil"></i>
                  </div>
                  <div className="description">
                    <h5 className="info-title">Subtítulo 3</h5>
                    <p>
                      Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                    </p>
                  </div>
                </div>
                <div className="info info-horizontal">
                  <div className="icon icon-success">
                    <i className="now-ui-icons files_single-copy-04"></i>
                  </div>
                  <div className="description">
                    <h4 className="info-title">Subtítulo 4</h4>
                    <p>
                      Parrafo de descripción de sección Parrafo de descripción de sección Parrafo de descripción de sección 
                  Parrafo de descripción de sección Parrafo de descripción de sección.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        
      </div>
    </>
  );
}

export default Features;
