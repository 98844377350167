import React from "react";


import BasePageView from "views/BasePageView.js";

import AboutUs from "components/AboutUs.js";
import ClientesLogos from 'components/ClientesLogos';

function NosotrosView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <AboutUs />
        {/*<div className="wrapper-empty"></div>*/}
      </div>
      <ClientesLogos />        
      </BasePageView>
  );
}

export default NosotrosView;
