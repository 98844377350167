import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import {dGlobal} from "functions/DGlobal";


// core components
import Post2Header from "components/Headers/Post2Header.js";

import ceoIcon from 'assets/img/ceo.svg';
import managerIcon from 'assets/img/manager.svg';
import seller1Icon from 'assets/img/seller1.svg';
import seller2Icon from 'assets/img/seller2.svg';

import r3_icon from 'assets/img/r3_icon.svg';
import ambient_icon from 'assets/img/ambient_icon.svg';
import bio_icon from 'assets/img/bio_icon.svg';

function Post2() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper">
        <Post2Header />
        
        <div className="text-center">
          <Container>
            <Row>
            <Col md="5">
            <div className="aboutus-imgheader" style={{
            backgroundImage: "url(" + require("assets/img/post2_image.jpg") + ")", backgroundSize: "cover", backgroundPosition: "center center"
          }}>
                
              
            </div>    
            </Col>
            <Col className="mr-auto ml-auto" md="6">
              <h4 className="description text-justify">
                

                Los regalos publicitarios son un medio de comunicación continuo entre una empresa y sus clientes. Son artículos que atraen la atención de los consumidores, 
                fomentando el posicionamiento de marca de las empresas en el mercado.<br /><br />

                Un artículo publicitario es una herramienta muy útil para el posicionamiento de una marca, ya que es más fácil que los clientes y consumidores recuerden la empresa 
                que les regaló el producto. Cabe destacar que el recuerdo será mucho más duradero si va personalizado con el logotipo de la empresa.<br /><br />

                

              </h4>
            </Col>  
            </Row>  
            
            <Row>
            <Col className="mr-auto ml-auto" md="11">
              <h4 className="description text-justify">
                Por otra parte, la industria de los productos publicitarios es una de las más versátiles y vanguardistas ya que ayudan a que las empresas proyecten una buena imagen.

              </h4>
            </Col>  
            
            </Row>  
            <div className="separator-line bg-info"></div>
         
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Dentro de la propia empresa</h2>
                    <h4 className="description text-justify">
                      Es importante que dentro de la propia empresa los empleados cuenten con regalos publicitarios con la marca para la que trabajan. La razón es que si los clientes 
                      ven bolígrafos, tazas o carpetas (entre otros) personalizadas, perciben los valores de marca de unidad, coherencia y fuerza. Lo que se traduce en un aumento de las ventas.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Entre empresas</h2>
                    <h4 className="description text-justify">
                      Los regalos publicitarios entre empresas son fundamentales para agradecer la confianza puesta el uno en el otro, para conservar alianzas y para mejorar la imagen corporativa.<br /><br />

                      Tarjeteros, bolígrafos, USB, relojes, etc. El producto que mejor se adapte a los valores de tu empresa, personalizado con tu logotipo.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Beneficios de regalar artículos publicitarios</h2>
                    <h4 className="description text-justify">
                        Una forma de que una empresa progrese y aumente los ingresos es invertir en publicidad para el negocio. Siempre orientado a aumentar el número de clientes y fidelizar a los que ya lo sean.<br /><br /> 

                        Uno de los mejores soportes publicitarios son los productos promocionales, ya que cuentan con muchos aspectos que beneficiarán a tu negocio.

                        
                    </h4>    
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Aumento de las ventas</h2>
                    <h4 className="description text-justify">
                        Los artículos publicitarios están diseñados para generar ingresos. Esto se debe a que ayudan a crear una asociación positiva entre el usuario y la empresa que los ha regalado.<br /><br /> 

                        Además, es más fácil que los clientes recomienden una empresa que ha tenido un detalle con ellos a que recomienden una que no les ha obsequiado nada.
                        
                    </h4>    
                  </Col>
                </Row>

                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">La gente adora recibir regalos</h2>
                    <h4 className="description text-justify">
                        Un regalo tiene una connotación positiva, lo que se traduce en un pensamiento positivo hacia la empresa que se lo ha proporcionado.<br /><br /> 

                        Este tipo de pensamiento se producen sin tener en cuenta el tipo de regalo que se ha recibido. No obstante, es importante destacar que cuanto más se adapte ese 
                        regalo a los valores de la empresa, mejor será el resultado.
                        
                        
                    </h4>    
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Recuerdo de Marca</h2>
                    <h4 className="description text-justify">
                        Una ventaja muy importante es que los regalos promocionales fomentan el recuerdo de marca. Especialmente si contienen el logotipo estampado, ya la marca estará presente en el recuerdo del cliente.
                        
                        
                    </h4>    
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Sin mucho coste</h2>
                    <h4 className="description text-justify">
                        Es una inversión relativamente menor que una campaña publicitaria, pero se pueden conseguir los mismos resultados o incluso mejores.
                        
                        
                    </h4>    
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Mayor cobertura</h2>
                    <h4 className="description text-justify">
                        Los regalos publicitarios suelen pasar de mano en mano. Por ejemplo, es muy común prestar un bolígrafo. Si tu marca se encuentra estampada en el mismo, 
                        llegas a más personas de las que en un principio habías pensado. Es una forma de que hagan publicidad gratuita de tu marca.<br /><br /> 

                        En pocas palabras, una gran ventaja de los artículos publicitarios es que no sólo llegas a tus clientes, sino que también a tus clientes potenciales.
                        
                        
                    </h4>    
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Fidelización</h2>
                    <h4 className="description text-justify">
                        La persona que reciba ese regalo recordará tu marca con mayor facilidad, pero lo importante es que los clientes que reciben artículos publicitarios suelen ser más fieles.<br /><br /> 

                        Un detalle con tus clientes, además de buen trato, ayudará a que prefieran los productos o servicios de tu empresa frente a la competencia.
                        
                        
                    </h4>    
                  </Col>
                </Row>

                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">¿Qué artículo promocional escojo?</h2>
                    <h4 className="description text-justify">
                        Casi cualquier regalo publicitario personalizado es perfecto para plasmar la imagen de marca de una empresa, pero es importante que se ajuste lo mejor posible a tus necesidades.<br /><br /> 

                        El producto estrella son los USB personalizados. Estos artículos electrónicos son útiles, atractivos y muy demandados. Son productos que siempre se les va a dar un uso, 
                        incluso serán compartidos con familiares y amigos. Es una de las mejores formas de ampliar el conocimiento de los clientes potenciales sobre tu empresa.<br /><br /> 

                        Como ves, los artículos promocionales son un medio de comunicación excepcional para llegar a tus clientes y clientes potenciales de forma sencilla. Es una herramienta 
                        imprescindible a incluir en una estrategia de marketing.<br /><br /> 

                        En Promo estamos especializados en los regalos de empresa personalizados. Tenemos un amplio catálogo en el que podrás elegir el producto que mejor se adapte a tus necesidades.
                        
                        <br /><br /><br /><br /><br /><br />
                    </h4>    
                  </Col>
                </Row>


              </Container>
            </div>
        
      </div>
    </>
  );
}

export default Post2;
