import React, { useState, useCallback, useRef } from "react";

// reactstrap components
import { Card, Container, Row, Col, Button } from "reactstrap";


function Politicas() {
  
  return (
    <>
      <div className="cd-section" id="features">

        <div className="">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <h4 className="title">Políticas generales de venta</h4>
                <p className="politicas">
                  Los precios unitarios NO incluyen el 16% de IVA (agregar al total de pedido)
Precios sujetos a cambios hasta autorización de boceto y pago de anticipo.
Todos nuestros precios son LAB nuestras oficinas (Guadalajara, Jalisco)
Una vez realizado pedido y realizado anticipo, NO SE ACEPTAN DEVOLUCIONES O CANCELACIONES
Material sujeto a disponibilidad, en caso de no haber existencia se propone otro producto, el cual puede generar cambio en precio.
No se hace devolución de anticipos, tanto en diseños de logo o productos.
El tiempo de entrega de nuestros productos es una vez realizado anticipo, confirmada existencia y realizado boceto.
El saldo deberá ser liquidado contra entrega (LAB GDL) o antes de envío local o nacional (cotizar aparte)
Se solicita el anticipo del 70% del total, incluyendo IVA.
En caso de campaña política, se solicita el TOTAL DEL PEDIDO por adelantado. (Sin excepción) Si requiere envío, pago adelantado del envío.
Nos reservamos el derecho de entregar con una diferencia mayor o menos al 3% del pedido. (Previo aviso al cliente)
*No se realiza factura por ANTICIPO, toda factura en 2 pagos, se realizará con PPD.
Cotizaciones con vigencia máxima de 1 semana, en caso de cambio, se notifica al cliente.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div id="devoluciones"></div>
        <div className="">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <h4 className="title">Devoluciones</h4>
                <p className="politicas">
                  Aplica únicamente por falla de fabricación del producto, el cual será sujeto a verificación.
    No aplica devolución por error en diseño ya autorizado, ni cambio de color.



                </p>
              </Col>
            </Row>
          </Container>
        </div>
        
        <div id="garantias"></div>
        <div className="">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <h4 className="title">Garantía</h4>
                <p className="politicas">
                  Todos nuestros productos tienen garantía de fábrica.
Una vez procesado/impreso fuera de nuestros talleres, NO APLICA GARANTÍA.
Productos realizados por nosotros APLICA GARANTÍA de impresión y de fábrica en el material.


                </p>
              </Col>
            </Row>
          </Container>
        </div>
        
        
        <div id="envios"></div>
        <div className="">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <h4 className="title">Envíos</h4>
                <p className="politicas">
                  Realizamos envíos locales y a toda la República Mexicana.
No contamos con convenios en paqueterías.
Todo envío local será cotizado en el momento, puede haber cambio de precio de un momento a otro.
Para envíos REPÚBLICA MEXICANA, sería por la paquetería de su gusto POR COBRAR.
En caso de ENVÍO LOCAL, se requiere PAGO DEL ENVÍO, previo a realizarlo.
Si es envío por medio de plataforma digital, PAGO PREVIO A ENVÍO, de lo contrario no será posible realizarlo. (Tomar en cuenta pago total del pedido)
Los tiempos de envío local o foráneo, no corresponden a PROMO. (rastreo por parte de paqueterías).



                </p>
              </Col>
            </Row>
          </Container>
        </div>
        
      </div>
      
    </>
  );
}

export default Politicas;
