import React from "react";


import BasePageView from "views/BasePageView.js";

import Post1 from "components/Post1.js";

function BlogPost1View() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Post1 />
      </div>
      </BasePageView>
  );
}

export default BlogPost1View;
