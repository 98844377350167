import React from "react";

// reactstrap components

// core components
import PromoNavbar from "components/Navbars/PromoNavbar.js";
import Footer from "components/Footer.js";
import Header from "components/Header.js";

import { Route, Switch, useLocation } from 'react-router-dom'

import WhatsAppWidget from 'react-whatsapp-widget'
import 'assets/css/whatsappbtn.css'

import CookieConsent from "react-cookie-consent";



import {dGlobal} from "functions/DGlobal";
import iconLogo from '../assets/img/iconLogo.svg' ;

const IconLogo = () => <img src={iconLogo} height="24px" />;

function BasePageView(props) {
  const { pathname, hash } = useLocation();
  const location = useLocation()
    
  React.useEffect(() => {
    
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo({top: -180, left: 0, behavior: 'smooth'});
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        
       console.log('hash id', id);
        
        const element = document.getElementById(id);
        if (element) {
          var screenPosition = element.getBoundingClientRect();  
          
          console.log('screenPosition', screenPosition, element, element.offsetTop);  
          
          window.scrollTo({top: element.offsetTop - 180, left: 0, behavior: 'smooth'});
          //element.scrollIntoView(false);
        }
      }, 0);
    }
    
    document.body.classList.add("sections-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    var href = window.location.href.substring(
      window.location.href.lastIndexOf("#/") + 2
    );
    var hrefId = href.substring(href.lastIndexOf("#") + 1);
    if (href.lastIndexOf("#") > 0) {
        /*
      document.getElementById(hrefId).scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest"
      });*/
    }
    return function cleanup() {
      document.body.classList.remove("sections-page");
      document.body.classList.remove("sidebar-collapse");
    };
  }, [location]);
  
  return (
    <>
      
      <div className="header-margen"></div>
      {props.children}
      <Footer />
      <CookieConsent debug={false} disableStyles={true} buttonText='Acepto el uso de "cookies"' location="bottom" buttonClasses="btn btn-primary" containerClasses="container-cookies-consent" contentClasses="content-cookies-consent" buttonWrapperClasses="button-cookies-consent" >
        Este sitio web utiliza "cookies" propias para mejorar su experiencia de navegación. No guardamos información confidencial {" "}
      </CookieConsent>

      <div className="whastapp-btn">
      <WhatsAppWidget IconLogo={IconLogo} phoneNumber='+523323674059' message='Hola!, 👋🏼 ¿podemos ayudarte?' companyName="Promo" placeholder="Escribe tu mensaje" sendButton="Enviar" textReplyTime="Respondemos en el mismo día" />
      <IconLogo />
      </div>
      
    </> 
  );
}

export default BasePageView;
