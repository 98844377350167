import React from "react";


import BasePageView from "views/BasePageView.js";

import Post2 from "components/Post2.js";

function BlogPost2View() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Post2 />
      </div>
      </BasePageView>
  );
}

export default BlogPost2View;
