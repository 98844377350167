import React from "react";


import BasePageView from "views/BasePageView.js";

import Facturacion from "components/Facturacion.js";


function FacturacionView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Facturacion />
      </div>
      </BasePageView>
  );
}

export default FacturacionView;
