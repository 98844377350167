/*eslint-disable*/
import React, { useState, useCallback, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  UncontrolledTooltip
} from "reactstrap";

import { BrowserView, MobileView, isBrowser, isMobile } from 'react-device-detect';
import CookieConsent, { Cookies, resetCookieConsentValue } from "react-cookie-consent";

import moment from 'moment';

import {dGlobal} from "functions/DGlobal";
import { useLocalStorage } from "components/useLocalStorage";
import ModalLogin from "components/ModalLogin";
import ModalOk from "components/ModalOk";

import logo from '../../assets/img/logo.svg';
import logoMovil from '../../assets/img/logoMovil.svg';
import logoMovil_cancer_mama from '../../assets/img/logoMovil_cancer_mama.svg';
import logoMovil_muertos from '../../assets/img/logoMovil_muertos.svg';
import logoMovil_buenfin from '../../assets/img/logoMovil_buenfin.svg';
import logoMovil_navidad from '../../assets/img/logoMovil_navidad.svg';

import banner from '../../assets/img/banner_header.svg';
import banner_cancer_mama from '../../assets/img/banner_header_cancer_mama.svg';
import banner_muertos from '../../assets/img/banner_header_muertos.svg';
import banner_buenfin from '../../assets/img/banner_header_buenfin.svg';
import banner_navidad from '../../assets/img/banner_header_navidad.svg';

import ScrollTransparentNavbar from "components/Navbars/ScrollTransparentNavbar.js";

function PromoNavbar() {
  
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [navbarOpen1, setNavbarOpen1] = React.useState(false);
  
  const [GUserToken, setGUserToken] = useLocalStorage("GUserToken", false);
  
  const refModalLogin =  useRef();
  const refModalOk =  useRef();
  
  const navigate = useNavigate();
  
  const curYear = moment().year();
  const curDate = moment();
  
  let curBanner = banner;
  let curBannerCSS = "bg-white fixed-top";
  let curLogoMovil = logoMovil;
  
  if(curDate.isBetween(moment("01/10/" + curYear, "DD/MM/YYYY"), moment("28/10/" + curYear, "DD/MM/YYYY"), 'days', '[]')){
      curBanner = banner_cancer_mama;
      curBannerCSS = "bg-white bg-pink fixed-top";
      curLogoMovil = logoMovil_cancer_mama;
      console.log('banner_cancer_mama');
  }
  else if(curDate.isBetween(moment("29/10/" + curYear, "DD/MM/YYYY"), moment("02/11/" + curYear, "DD/MM/YYYY"), 'days', '[]')){
      curBanner = banner_muertos;
      curBannerCSS = "bg-white bg-muertos fixed-top";
      curLogoMovil = logoMovil_muertos;
  }
  else if(curDate.isBetween(moment("03/11/" + curYear, "DD/MM/YYYY"), moment("30/11/" + curYear, "DD/MM/YYYY"), 'days', '[]')){
      curBanner = banner_buenfin;
      curBannerCSS = "bg-white bg-buenfin fixed-top";
      curLogoMovil = logoMovil_buenfin;
  }
  else if(curDate.isBetween(moment("01/12/" + curYear, "DD/MM/YYYY"), moment("31/12/" + curYear, "DD/MM/YYYY"), 'days', '[]')){
      curBanner = banner_navidad;
      curBannerCSS = "bg-white bg-navidad fixed-top";
      curLogoMovil = logoMovil_navidad;
  }
  else if(curDate.isBetween(moment("01/01/" + curYear, "DD/MM/YYYY"), moment("03/01/" + curYear, "DD/MM/YYYY"), 'days', '[]')){
      curBanner = banner_navidad;
      curBannerCSS = "bg-white bg-navidad fixed-top";
      curLogoMovil = logoMovil_navidad;
  }
  
  /*
  curBanner = banner_navidad;
  curBannerCSS = "bg-white bg-navidad fixed-top";
  curLogoMovil = logoMovil_navidad;
 //*/

  React.useEffect(() => {
    
    dGlobal.event.on('onLogin', onLogin);
            
    return () => {
        dGlobal.event.removeListener('onLogin', onLogin);
    }

  }, []);
  
  const logIn = () => {
      refModalLogin.current.open();
  };
  
  const onLogin = () => {
      console.log('onLogin');
      
      const user_token = dGlobal.getStorage('user_token');
      
      if(user_token){
        setGUserToken(user_token);
        
        navigate("/admin");  
        
        refModalOk.current.open({message: 'Se ha iniciado sesión de forma exitosa.', title: 'Sesión Iniciada'});
        
        
    fetch(process.env.REACT_APP_API_URL + "/api/admin/", {
	method: "GET" ,
	timeoutInterval: 15000,
	headers: {
            'Authorization': 'Bearer ' + user_token,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
        },
        /*
        body: JSON.stringify({
            'client_id': 'none',
            'client_secret': 'none',
            'grant_type': 'none'
        })*/
    })  
    .then(response => response.json())
    .then(responseJson => {
        console.log('responseJson', responseJson);

        if(!responseJson.error){
            

        }
        else {
            
        }
        
    })
    .catch(error => {
        console.error(error);
    });
    
      }
  };
  
  const resetCookies = () => {
      
      dGlobal.setStorage('user_token', false);
      setGUserToken(dGlobal.getStorage('user_token'));
      
      resetCookieConsentValue();
      navigate("/");
      
      refModalOk.current.open({message: 'Se han removido todas las "cookies" de forma exitosa.', title: 'Limpiar "cookies"'});
      
  };
  
  const logOut = () => {
      
      dGlobal.setStorage('user_token', false);
      setGUserToken(dGlobal.getStorage('user_token'));
      navigate("/");  
      
      refModalOk.current.open({message: 'Se ha cerrado la sesión del usuario de forma exitosa.', title: 'Cerrar Sesión'});
      
  };
  
  
  return (
    
    <>
      
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className={curBannerCSS} expand="lg">
        <Container>
          <MobileView>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img src={curLogoMovil} height="120px" />
            </NavbarBrand>
            
          </div>
          </MobileView>
          
          {isBrowser && <>
          <div className="navbar-translate">
            <NavbarBrand to="/" tag={Link} id="navbar-brand">
            <img src={curBanner} height="136px" />
            {/*<img src={logo} height="120px" />*/}
            </NavbarBrand>
            
          </div>
          
          
          </>}
          
        </Container>
        <Container className="navbar-container">  
          
         <Container>
          <div className="navbar-translate">
            <button
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              className="navbar-toggler"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
              
          <Collapse isOpen={collapseOpen} navbar>
            <Nav className="mx-auto" navbar>
                  <NavItem>
                    <NavLink onClick={() => navigate("/")}>
                      Inicio
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/productos")} >
                      Productos y Servicios
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/catalogo")} >
                      Catálogos
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/nosotros")} >
                      Nosotros
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink onClick={() => navigate("/contacto")}>
                      Contacto
                    </NavLink>
                  </NavItem>
                </Nav>
           
            <Nav className="nav" navbar>
                  <NavItem>
                    <NavLink
                      href="https://twitter.com/promo_articulo?s=21"
                      target="_blank"
                    >
                      <i className="fab fa-twitter"></i>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://www.facebook.com/Promo.Ofertas"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square"></i>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="https://instagram.com/promo_articulos?utm_medium=copy_link"
                      target="_blank"
                    >
                      <i className="fab fa-instagram"></i>
                    </NavLink>
                  </NavItem>
                </Nav>
                <BrowserView>
                <Nav className="nav" navbar>
                  <UncontrolledDropdown nav>
                        <DropdownToggle
                          caret
                          color="default"
                          data-toggle="dropdown"
                          href="#promo"
                          id="navbarDropdownMenuLink"
                          nav
                          onClick={e => e.preventDefault()}
                        >
                          <i
                            aria-hidden={true}
                            className="now-ui-icons users_circle-08"
                          ></i>
                        </DropdownToggle>
                        <DropdownMenu
                          aria-labelledby="navbarDropdownMenuLink"
                          right
                        >
                          <DropdownItem header>Usuario</DropdownItem>
                          <DropdownItem onClick={() => logIn()} style={{display: GUserToken ? 'none' : 'default'}}>
                            Iniciar Sesión
                          </DropdownItem>
                          <DropdownItem onClick={() => logOut()} style={{display: !GUserToken ? 'none' : 'default'}}>
                            Cerrar Sesión
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem onClick={() => navigate("/admin")} style={{display: !GUserToken ? 'none' : 'default'}}>
                            Editar ofertas
                          </DropdownItem>
                          <DropdownItem onClick={() => resetCookies()} >
                            Limpiar "cookies"
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                </Nav>
                </BrowserView>
          </Collapse>
         
        </Container>
        </Container>
      </Navbar>
      
      <ModalLogin id="c4" title="Iniciando Sesión..." ref={refModalLogin} />
      <ModalOk id="c4" title="Información" ref={refModalOk} />
    </>
  );
}

export default PromoNavbar;
