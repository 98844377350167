import React, { useState, useCallback, useRef, useImperativeHandle, forwardRef } from "react";
import type {Node} from 'react';


// reactstrap components
import { Card, Container, Row, Col } from "reactstrap";
import {dGlobal} from "functions/DGlobal";

import {
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  Collapse,
  FormGroup,
  UncontrolledTooltip,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";

import {
  Pagination,
  PaginationItem,
  PaginationLink,
  
} from "reactstrap";


const ModalLogin = (props, ref): Node => {
  
  const [modalOpen, setModalOpen] = useState(false);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  
  
  const refModal =  useRef();
  const refForm =  useRef();
    
  const buttonStyle = {
      display: 'block',
      margin: '10px auto'
    };  
    
  React.useEffect(() => {
      
    console.log("ModalDownload mounted", props);
    
    return () => {
        console.log('ModalDownload  destroy');
    }

  }, []);
  
  useImperativeHandle(ref, () => ({
    // methods connected to `ref`
    open: (data) => { open(data) },
    close: () => { close() }
  }));
  
  const open = (data) => {
      
    setModalOpen(true);
  
  };
  
  const close = () => {
    setModalOpen(false);
  };
  
  const doLogin = () => {
      
    console.log('doLogin');  
      
    let formData = new FormData(refForm.current);

    fetch(process.env.REACT_APP_API_URL + "/api/admin/login", {
	method: "POST" ,
	timeoutInterval: 15000,
	body: formData
    })  
    .then(response => response.json())
    .then(responseJson => {
        console.log('responseJson', responseJson);

        if(!responseJson.error){
            dGlobal.setStorage('user_token', responseJson.token);
            setModalOpen(false);
            
            dGlobal.event.emit('onLogin', {});

        }
        else {
            
        }
        
    })
    .catch(error => {
        console.error(error);
    });
   
  };
  
  return (
    <>
      <Modal
                isOpen={modalOpen}
                style={{
                    maxWidth: 500,
                    maxHeight: 280,
                    
                  }}
                  
                ref={refModal}  
                
                centered={true}
              >
                <div className="modal-header justify-content-center">
                  <button
                    aria-hidden={true}
                    className="close"
                    onClick={() => setModalOpen(false)}
                    type="button"
                  >
                    <i className="now-ui-icons ui-1_simple-remove"></i>
                  </button>
                  <h4 className="title title-up">Iniciando sesión</h4>
                </div>
          <ModalBody className="modal-body-ok">
            <Container>
            <Row>
                <Card className="card-login card-plain">
                  <form ref={refForm} action="" className="form" method="">
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (firstFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Correo Electrónico"
                          type="text"
                          name="email"
                          onFocus={() => setFirstFocus(true)}
                          onBlur={() => setFirstFocus(false)}
                          autoComplete="on" 
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border input-lg" +
                          (lastFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons text_caps-small"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Contraseña"
                          type="password"
                          name="password"
                          onFocus={() => setLastFocus(true)}
                          onBlur={() => setLastFocus(false)}
                        ></Input>
                      </InputGroup>
                    </CardBody>
                    
                    
                  </form>
                </Card>
              
            </Row>
          </Container>
          </ModalBody>
          <ModalFooter >
            <div style={{ flex: 1, justifyContent: 'flex-start' }} />
            <Button className="btn-round btn-login" color="secondary" onClick={() => setModalOpen(false)} >Cancelar</Button>
            <Button className="btn-round btn-login" color="primary" onClick={() => doLogin()} >Iniciar Sesión</Button>
          </ModalFooter>
                
              </Modal>
              
    </>
  );
}

export default forwardRef(ModalLogin);
