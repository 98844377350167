/*eslint-disable*/
import React from "react";

// reactstrap components
import {
  Button,
  FormGroup,
  Form,
  Input,
  NavLink,
  Container,
  Row,
  Col
} from "reactstrap";


import clients_logo_1 from 'assets/img/clients_logo_1.svg';
import clients_logo_2 from 'assets/img/clients_logo_2.svg';
import clients_logo_3 from 'assets/img/clients_logo_3.svg';
import clients_logo_4 from 'assets/img/clients_logo_4.svg';
import clients_logo_5 from 'assets/img/clients_logo_5.svg';
import clients_logo_6 from 'assets/img/clients_logo_6.svg';
import clients_logo_7 from 'assets/img/clients_logo_7.svg';
import clients_logo_8 from 'assets/img/clients_logo_8.svg';
import clients_logo_9 from 'assets/img/clients_logo_9.svg';
import clients_logo_10 from 'assets/img/clients_logo_10.svg';
// core components

function ClientesLogos() {
  
  return (
    <>
      <div className="text-center social-line-white">
            <Container>
              <Row className="justify-content-center">
                <Col md="12">
                  <h4 className="title">Nuestros Clientes </h4>
                  <h4 className="description">Somos la mejor opción sólida y confiable para atender a las necesidades de promoción de tu empresa o negocio, nuestros clientes nos respaldan, estos son algunos de nuestros clientes satisfechos que han confiado en nosotros, los cuales incluyen empresas de diversos giros con un amplio abanico de necesidades.</h4>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="social-line-top">
            <Container>
              <Row className="justify-content-center social-line-white" >
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_1} height="87px" />
                  </Row>
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_2} height="87px" />
                </Row>
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_3} height="87px" />
                </Row>  
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_4} height="87px" />
                </Row>  
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_5} height="87px" />
                </Row>  
                </Col>
              </Row>
            </Container>
          </div>
          <div className="social-line">
            <Container>
              <Row className="justify-content-center social-line-white">
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_6} height="87px" />
                </Row>  
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_7} height="87px" />
                </Row>  
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_8} height="87px" />
                </Row>  
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_9} height="87px" />
                </Row>  
                </Col>
                <Col md="2">
                <Row className="justify-content-center">
                  <img src={clients_logo_10} height="87px" />
                </Row>  
                </Col>
              </Row>
            </Container>
          </div>
    </>
  );
}

export default ClientesLogos;
