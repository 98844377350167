import React from "react";


import BasePageView from "views/BasePageView.js";

import Post3 from "components/Post3.js";

function BlogPost3View() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Post3 />
      </div>
      </BasePageView>
  );
}

export default BlogPost3View;
