import React from "react";
// react plugin used to create DropdownMenu for selecting items
import Select from "react-select";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import {dGlobal} from "functions/DGlobal";


// core components
import Post1Header from "components/Headers/Post1Header.js";

import ceoIcon from 'assets/img/ceo.svg';
import managerIcon from 'assets/img/manager.svg';
import seller1Icon from 'assets/img/seller1.svg';
import seller2Icon from 'assets/img/seller2.svg';

import r3_icon from 'assets/img/r3_icon.svg';
import ambient_icon from 'assets/img/ambient_icon.svg';
import bio_icon from 'assets/img/bio_icon.svg';

function Post1() {
  const [specialitySelect, setSpecialitySelect] = React.useState(null);
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [emailFocus, setEmailFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.add("about-us");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("about-us");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <div className="wrapper">
        <Post1Header />
        <div className="text-center">
          <Container>
            <Row>
            <Col md="5">
            <div className="aboutus-imgheader" style={{
            backgroundImage: "url(" + require("assets/img/post1_image.jpg") + ")", backgroundSize: "cover", backgroundPosition: "center center"
          }}>
                
              
            </div>    
            </Col>
            <Col className="mr-auto ml-auto" md="6">
              <h4 className="description text-justify">
                La promoción es necesaria para alcanzar a nuestro mercado objetivo y también para retenerlos. <br/> <br/>
                Existen distintas formas de promocionar, entre ellas están: Los concursos, la promoción en redes sociales, la mercadotécnia de compra por correo, las muestras gratis, promoción de puntos de venta,
                mercadotécnia en puntas de góndola, programas de referencia de incentivos para el consumidor y muchas más. 

              </h4>
            </Col>  
            </Row>  
            
            <Row>
            <Col className="mr-auto ml-auto" md="11">
              <h4 className="description text-justify">
                Pero existe una que nos brinda muchas ventajas y además es muy accesible y económica, esta es, la promoción por medio de artículos promocionales. 
                El regalar artículos promocionales es una de las acciones promocionales más efectivas que existen, con ellos se puede plasmar la imagen de un negocio en distintos objetos
                y ponerlos a la vista del prospecto durante mucho tiempo, estando ahí cuando requiera de tus servicios.

              </h4>
            </Col>  
            
            </Row>  
            <div className="separator-line bg-info"></div>
         
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Qué son los artículos promocionales?</h2>
                    <h4 className="description text-justify">
                      Los artículos promocionales, también conocidos como articulos publicitarios, son productos que abarcan desde un simple bolígrafo hasta un cargador portátil personalizado, en estos, se estampa el logotipo o información variada de una empresa para así lograr promocionar masivamente a dicha empresa. Existen varios objetivos para el uso de artículos promocionales, pueden ser el aumento de ventas de la compañía, permanecer presentes en la mente del consumidor o bien, el más común, promocionarse ampliamente. Si se habla de articulos promocionales seguramente lo primero que se nos venga a la mente será un bolígrafo personalizado, este es uno de los objetos más utilizados para la promoción, llegando a utilizarse desde campañas electorales hasta eventos de cualquier tipo. La razón de esto es su gran rentabilidad, ya que se puede disponer de muchas unidades a un precio muy económico.
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Actualidad en los artículos promocionales</h2>
                    <h4 className="description text-justify">
                      Actualmente, el empleo de los artículos promocionales en estrategias de promoción es tan importante que la industria de promociones en México tiene un valor de 11 mil 970 millones de pesos (fuente Merca 2.0). Las empresas de promoción sirven de apoyo a marcas para poder incorporar una gran variedad de artículos promocionales en sus estrategias de promoción con el objetivo de establecer un vínculo perdurable con los consumidores. Sin duda el uso de los artículos promocionales es una parte integral de toda estrategia comercial, y su efectividad es comprobable, ya que estadísticas apuntan que más del 75% de las personas que reciben artículos promocionales los conservan por más de 6 meses, teniendo en cierto modo ‘cerca’ a la marca, además, después de recibir dicho articulo cerca del 62% decide hacer algún negocio o compra con la empresa. Cabe mencionar también que más del 84% de las personas que reciben un artículo promocional recuerda frecuentemente a la marca asociada..
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Ventajas que nos presenta el uso de artículos promocionales</h2>
                    <h4 className="description text-justify">
                        El usar artículos promocionales como estrategia de promoción nos presenta muchas ventas:<br /><br />

    - Permite la retención de nuestros clientes y captar un mayor número de estos.<br />
    - Transmite el valor y concepto de la marca por medio del articulo promocional.<br />
    - Se convierte en un punto publicitario mas de la empresa.<br />
    - El receptor del articulo recibe el mismo como lo haría con relaciones públicas de la empresa, creando así una predisposición positiva y favorable hacia la empresa.<br />
    - Los artículos promocionales refuerzan la marca. Un folleto con información de la empresa no es suficiente, se tiene que dar algo útil y de valor para que se recuerde con facilidad.<br />
<br /><br /> 
                        
                    </h4>    
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Que articulos promocionales utilizar en mi empresa</h2>
                    <h4 className="description text-justify">
                        Antes de escoger los artículos promocionales que se utilizaran es necesario definir los objetivos de la campaña promocional, estos pueden ser aumentar las ventas de la compañía,
                        generar mayor promoción de nuestra marca, fidelizar clientes, entre otros. <br /><br />
                        También se debe definir el público o mercado objetivo que recibirá dichos artículos promocionales, esto dependerá de algunas variables que nos permitirán escoger adecuadamente
                        nuestros promocionales. Las variables pueden ser: Nivel socioeconómico, nivel cultural, religión, edad, sexo, ocupación, entre otras. <br /><br />
                        
                        Una vez definidos los objetivos y el mercado objetivo se puede comenzar a escoger los artículos promocionales de acuerdo a los resultados de dicha preparación.<br /><br />

                        Es importante recalcar que independientemente del público objetivo y de los objetivos de la campana se deberán escoger artículos promocionales que tengan relación con el rubro 
                        de la empresa y que también sean útiles para nuestro público objetivo. Por ejemplo, si nuestro público objetivo son amas de casa, se podría dar articulos de cocina 
                        como mandiles o tazas medidoras, si nuestro público objetivo son estudiantes podríamos dar útiles escolares, como mochilas, lápices, bolígrafos, sacapuntas, etc, y 
                        si nuestro público objetivo fueran conductores o transportistas podríamos dar medidores de aire o galoneras.
                        
                    </h4>    
                  </Col>
                </Row>

                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Algunos consejos</h2>
                    <h4 className="description text-justify">
                        Si no hay tiempo de investigar o definir al público objetivo siempre podemos recurrir al artículo promocional más común de todos: El bolígrafo. Este es uno de los más usados 
                        por empresas para promocionarse, seguido de las bolsas ecológicas y de los llaveros. Es uno los artículos promocionales más económicos que existe, permitiendo generar una gran 
                        cantidad de unidades y un gran alcance a un precio muy razonable.
                        
                        
                    </h4>    
                  </Col>
                </Row>
                
                <Row>
                  <Col className="mr-auto ml-auto" md="11">
                    <h2 className="title">Conclusión</h2>
                    <h4 className="description text-justify">
                        No cabe duda que el uso de artículos promocionales es imprescindible en cualquier estrategia de promoción, generan un gran alcance, refuerzan nuestra marca, logran fidelizar a 
                        nuestros clientes y además, son una de las formas de promoción más económicas que existen, el uso de artículos promocionales nos brinda un alcance seguro y duradero de nuestra 
                        marca en los hogares de nuestros consumidores y prospectos.
                        
                        <br /><br /><br /><br /><br /><br />
                    </h4>    
                  </Col>
                </Row>

              </Container>
            </div>
          
          
          
        
      </div>
    </>
  );
}

export default Post1;
