import React from "react";


import BasePageView from "views/BasePageView.js";

import Catalogo from "components/Catalogo.js";


function CatalogoView() {
  
  return (
      <BasePageView>        
      <div className="wrapper">
        <Catalogo />
      </div>
      </BasePageView>
  );
}

export default CatalogoView;
